import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Divider, Typography, CardMedia, useTheme, Box } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { debounce, isEmpty } from 'lodash-es';

import TrashIcon from 'assets/icons/Trash';
import MotionCard from 'components/MotionCard';
import ConfirmationBox from 'components/Common/ConfirmationBox';
import { useAppContext } from 'context/AppContext';
import { API, APIRoutes } from 'utils/api';
import { useAuth } from 'context/AuthContext';
import { QUANTITY_LIMITS } from 'constants/store';
import LoadingIconButton from 'components/Common/LoadingIconButton';
import { QuantityButton, QuantityInput } from './style';

const BasketItemCard = ({
  productId,
  quantity,
  regularCharge,
  pricePerItem,
  productName,
  imagePath,
}: BasketProduct) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { openModal } = useAppContext();
  const { setIsBasketEmpty } = useAuth();

  const [itemQuantity, setItemQuantity] = useState<number>(quantity);

  const { mutate: updateProduct } = useMutation(
    () =>
      API.post(APIRoutes.basket.add(productId), {
        quantity: itemQuantity,
        regularCharge,
      }),
    {
      onSuccess({ data: response }) {
        const { data } = response;
        queryClient.setQueryData('basket', data);
      },
    },
  );

  const { mutate: removeProduct, isLoading } = useMutation(
    () => API.delete(APIRoutes.basket.remove(productId)),
    {
      onSuccess({ data: response }) {
        const { data } = response;
        if (isEmpty(data?.basket)) {
          setIsBasketEmpty(true);
        }
        queryClient.setQueryData('basket', data);
      },
    },
  );

  const updateQuantity = useCallback(debounce(updateProduct, 400), []);

  useEffect(() => {
    if (quantity !== itemQuantity) {
      updateQuantity();
    }
  }, [itemQuantity]);

  return (
    <MotionCard whileHover={{ scale: 1.025 }}>
      <Box
        sx={{
          padding: '12px 24px 12px 16px',
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
          cursor: 'default',

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '8px',
          },
        }}
      >
        <CardMedia
          component="img"
          src={imagePath}
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.src = '/assets/images/placeholder.png';
          }}
          alt="basket item"
          sx={{
            width: '56px',
            height: '56px',
            [theme.breakpoints.down('md')]: {
              width: '100%',
              height: 'auto',
            },
          }}
        />
        <Typography
          lineHeight="18px"
          fontWeight={theme.typography.fontWeightBold}
          width="100%"
          sx={{
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          {productName}
        </Typography>
        <Box display="flex" alignItems="center" gap="9px">
          <Typography
            fontSize={theme.typography.smallFontSize}
            lineHeight="16.25px"
          >
            Qty
          </Typography>
          <QuantityInput
            readOnly
            value={itemQuantity}
            startAdornment={
              <QuantityButton
                onClick={() => {
                  if (itemQuantity > QUANTITY_LIMITS.min) {
                    setItemQuantity((prevQuantity) => prevQuantity - 1);
                  }
                }}
              >
                <RemoveRoundedIcon />
              </QuantityButton>
            }
            endAdornment={
              <QuantityButton
                onClick={() => {
                  if (itemQuantity < QUANTITY_LIMITS.max) {
                    setItemQuantity((prevQuantity) => prevQuantity + 1);
                  }
                }}
              >
                <AddRoundedIcon />
              </QuantityButton>
            }
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box width="100px" textAlign="center" flexShrink={0}>
          <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
            £{pricePerItem.toFixed(2)}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <LoadingIconButton
          isLoading={isLoading}
          onClick={() =>
            openModal(
              <ConfirmationBox
                text="Are you sure you want to delete this?"
                onClick={removeProduct}
              />,
            )
          }
          sx={{
            width: '40px',
            height: '40px',
            border: `solid 1px ${theme.palette.secondary.main}`,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <TrashIcon
            sx={{
              width: '24px',
              height: '24px',
              color: theme.palette.blue[900],
            }}
          />
        </LoadingIconButton>
      </Box>
    </MotionCard>
  );
};

export default BasketItemCard;
