/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Typography, Divider, useTheme } from '@mui/material';

import moment, { Moment } from 'moment';
import { useSearchParams } from 'react-router-dom';
import { last } from 'lodash-es';
import { METRICS_TYPE, MONTH_FILTER } from 'constants/bookingsMetrics';

import { API, APIRoutes } from 'utils/api';
import { useAuth } from 'context/AuthContext';
import { Wrapper } from 'pages/Account/Metrics/Wrapper';
import { FilterSelect } from 'pages/Account/Metrics/FilterSelect';
import MonthFilter from 'pages/Account/Metrics/MonthFilter';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import CalendarItem from 'components/Bookings/CalendarItem';
import NavigationIcon from 'assets/icons/NavigationIcon';
import { NavigationButton } from 'components/Rooms/style';
import EmptySection from 'components/Common/EmptySection';
import SadFace from 'assets/icons/SadFace';

const fetchBookingMetrics = async (filter: string, month: string) => {
  const { data } = await API.get(APIRoutes.user.bookingsMetrics(filter, month));
  return data;
};

const BookingsMetrics = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const paramFilter = searchParams.get('filter');
  const defaultFilter =
    paramFilter && METRICS_TYPE.some((el) => el.value === paramFilter)
      ? paramFilter
      : METRICS_TYPE[0].value;

  const monthParam = searchParams.get('month');
  const defaultMonthFilter =
    monthParam &&
    MONTH_FILTER.some(
      (el) => moment(el.value).format('DD-MM-YYYY') === monthParam,
    )
      ? monthParam
      : moment().startOf('month').toISOString();
  const [userFilter, setUserFilter] = useState(defaultFilter);
  const [monthFilter, setMonthFilter] = useState(defaultMonthFilter);

  const {
    data: bookingMetrics,
    isLoading: isLoadingBookings,
    isSuccess: isSuccessFetchingBookings,
  } = useQuery<any>(
    ['bookingMetrics', userFilter, monthFilter],
    () => fetchBookingMetrics(userFilter, monthFilter),
    { enabled: !!user?.isTeamLeader },
  );

  const monthChange = (option: string | Moment) => {
    setMonthFilter(moment(option).toISOString());
    setSearchParams(() => {
      searchParams.set('month', moment(option).toISOString());
      return searchParams;
    });
  };

  return (
    <Wrapper
      label="Bookings"
      childBoxHeight="100%"
      customSelector={
        <Box display="flex" gap={2}>
          <MonthFilter
            options={MONTH_FILTER}
            onSelect={monthChange}
            selectedValue={monthFilter}
          />
          <FilterSelect
            options={METRICS_TYPE}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            setSearchParams={setSearchParams}
          />
        </Box>
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            justifyItems: 'center',
          },
        }}
      >
        <NavigationButton
          onClick={() => monthChange(moment(monthFilter).subtract(1, 'M'))}
        >
          <NavigationIcon
            sx={{
              padding: '7px',
            }}
          />
        </NavigationButton>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              order: 2,
              gridColumn: '1 / span 2',
            },
          }}
        >
          <Typography fontWeight="bold">
            {moment(monthFilter).format('MMMM YYYY')}
          </Typography>
          <Divider sx={{ marginBottom: '10px' }} />
          {isLoadingBookings && (
            <LoadingSpinner minHeight="334px" width="100%" />
          )}
          {isSuccessFetchingBookings && (
            <Box component="span">
              {bookingMetrics?.data?.length > 0 ? (
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fill,minmax(307px, 1fr))"
                  gap={2}
                  width="100%"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      gridTemplateColumns:
                        'repeat(auto-fill,minmax(100%, 1fr))',
                    },
                  }}
                >
                  {bookingMetrics?.data.map((el: any) => (
                    <CalendarItem
                      key={`bookings-metrics-${el.resourceId}`}
                      nonClickable
                      checkIn={el.fromTime}
                      checkOut={el.toTime}
                      resource={{
                        name: el?.resourceName,
                        capacity: el?.resourceCapacity,
                        bookedBy: el?.coworkerFullName,
                      }}
                      {...el}
                    />
                  ))}
                </Box>
              ) : (
                <EmptySection
                  missing="there were no results found"
                  icon={<SadFace />}
                />
              )}
            </Box>
          )}
        </Box>
        {moment(monthFilter).isBefore(last(MONTH_FILTER)?.value) && (
          <NavigationButton
            onClick={() => monthChange(moment(monthFilter).add(1, 'M'))}
            sx={{
              [theme.breakpoints.down('md')]: {
                order: 1,
              },
            }}
          >
            <NavigationIcon
              sx={{
                padding: '7px',
                transform: 'rotate(180deg)',
              }}
            />
          </NavigationButton>
        )}
      </Box>
    </Wrapper>
  );
};

export default BookingsMetrics;
