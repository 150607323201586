/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import difference from 'lodash-es/difference';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient, useMutation } from 'react-query';

import PinIcon from 'assets/icons/PinIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import AccountIcon from 'assets/icons/AccountIcon';

import { useAuth } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';

import BookingOptions from 'components/Rooms/BookingsOptions';

import TimeField from 'components/Visitors/TimeField';
import { MONTHS, getDaysArray } from 'components/Rooms/helpers';
import Input from 'components/Form/TextInput';

import { API, APIRoutes } from 'utils/api';

export type Query = {
  address: string;
  arrived: boolean;
  arrivedAt: null | string;
  created: string;
  id: number;
  space: string;
  visitorArrival: string;
  visitorCompany: string;
  visitorEmail: string;
  visitorName: string;
  visitorPhone: string;
};

type FormTypes = Query & {
  location: string;
  month: string;
  day: string;
  time: string;
  visitorArrival: string;
  spaceId: string;
  visitorName: string;
  visitorCompany: string;
  visitorEmail: string;
  visitorPhone: string;
};

const schema = yup
  .object({
    visitorEmail: yup
      .string()
      .required('Email is required')
      .email('Incorrect e-mail'),
    visitorName: yup.string().required('Name is required').trim(),
    visitorCompany: yup.string().required('Company name is required').trim(),
    visitorPhone: yup.string().required("Visitor's phone is required").trim(),
  })
  .required();

const editVisitor = async (id: number, data: Query) => {
  API.put(APIRoutes.visitors.update(id), data);
};

const VisitorForm = ({
  initialValues,
  isSuccess,
  setShowToast,
  pastVisitor,
}: {
  initialValues?: Query;
  isSuccess?: boolean;
  pastVisitor?: boolean;
  setShowToast?: Dispatch<
    SetStateAction<{
      type: null | 'error' | 'success';
      open: boolean;
    }>
  >;
}) => {
  const { currentSpaceId, user, spaces } = useAuth();
  const queryClient = useQueryClient();
  const { closeModal } = useAppContext();
  const { snack, setSnack } = useToast();

  const { mutate: addVisitor, error } = useMutation(
    (data: Query) => API.post(APIRoutes.visitors.addVisitor, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('expected-visitors');
      },
    },
  );

  const theme = useTheme();

  const [isDisabledChoice, setIsDisabledChoice] = useState(false);

  const currentTimeRounded = moment().set({
    minutes: moment().get('minutes') - (moment().get('minutes') % 15) + 15,
    seconds: 0,
    milliseconds: 0,
  });

  const SPACES = spaces
    .filter((location) => location.id !== 0)
    .map((location) => ({
      label: location.shortName,
      value: location.id.toString(),
    }));

  const defautlLocation = useMemo(() => {
    if (currentSpaceId === 0) {
      return user?.spaceId.toString();
    }

    return currentSpaceId.toString();
  }, [user, currentSpaceId]);

  const defaultValues = useMemo(
    () => ({
      location: initialValues?.space
        ? spaces.find((x) => x.name === initialValues?.space)?.id.toString()
        : defautlLocation,
      month: initialValues?.visitorArrival
        ? moment(initialValues?.visitorArrival).startOf('month').toString()
        : moment().startOf('month').toString(),
      day: initialValues?.visitorArrival
        ? moment(initialValues?.visitorArrival).startOf('day').toString()
        : moment().startOf('day').toString(),
      time: initialValues?.visitorArrival
        ? moment(initialValues?.visitorArrival).toString()
        : currentTimeRounded.toString(),
      visitorName: initialValues?.visitorName ? initialValues?.visitorName : '',
      visitorCompany: initialValues?.visitorCompany
        ? initialValues?.visitorCompany
        : '',
      visitorEmail: initialValues?.visitorEmail
        ? initialValues?.visitorEmail
        : '',
      visitorPhone: initialValues?.visitorPhone
        ? initialValues?.visitorPhone
        : '',
    }),
    [currentSpaceId],
  );

  const methods = useForm<FormTypes>({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, getValues, setValue, formState, getFieldState } =
    methods;
  const values = getValues();

  const watchFields =
    difference(Object.values(values), Object.values(defaultValues)).length > 0;

  const isDirty =
    getFieldState('visitorName', formState).isDirty &&
    getFieldState('visitorCompany', formState).isDirty &&
    getFieldState('visitorEmail', formState).isDirty &&
    getFieldState('visitorPhone', formState).isDirty;

  useEffect(() => {
    const dayAdjustedToMonth = moment(values.day)
      .year(moment(values.month).year())
      .month(moment(values.month).month())
      .date(moment(values.day).date());

    const adjustTime = moment(values.time)
      .year(moment(values.month).year())
      .month(moment(values.month).month())
      .date(moment(values.day).date());

    setValue('day', moment(dayAdjustedToMonth).toString());
    setValue('time', adjustTime.toString());

    setIsDisabledChoice(!moment().isBefore(adjustTime));
  }, [values.month, values.day, values.time]);

  const onSubmit: SubmitHandler<FormTypes> = (data) => {
    if (data.day && data.time && data.month) {
      const startDate = moment()
        .startOf('day')
        .set({
          date: moment(new Date(data.day)).date(),
          month: moment(new Date(data.month)).month(),
          year: moment(new Date(data.month)).year(),
          hour: moment(new Date(data.time)).hours(),
          minute: moment(new Date(data.time)).minutes(),
        });

      const query = {
        visitorArrival: startDate.toISOString(),
        spaceId: data.location,
        visitorName: data.visitorName.trim(),
        visitorCompany: data.visitorCompany.trim(),
        visitorEmail: data.visitorEmail.trim(),
        visitorPhone: data.visitorPhone,
      };

      if (initialValues?.visitorName) {
        editVisitor(initialValues.id, query as any).then(() => {
          queryClient.invalidateQueries('expected-visitors');
          if (pastVisitor) queryClient.invalidateQueries('past-visitors');
        });
      } else {
        addVisitor(query as any);
      }
      closeModal();
      if (initialValues?.visitorName) {
        setShowToast?.({ open: true, type: isSuccess ? 'success' : 'error' });
      } else {
        setSnack({
          ...snack,
          message: error ? 'Unable to add new visitor' : 'New visitor added',
          open: true,
          type: error ? 'error' : 'success',
        });
      }
    }
  };

  const buttonText = useMemo(() => {
    if (pastVisitor) return 'Re-add visitor';
    if (initialValues?.visitorName) return 'Edit visitor';

    return 'Add visitor';
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          alignItems="center"
          marginBottom="32px"
          marginTop="41px"
        >
          <Icon
            component={AccountIcon}
            sx={{
              color: theme.palette.secondary.main,
              fill: 'none',
              width: '24px',
              height: '24px',
              marginRight: '8px',
            }}
          />
          <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
            Visitor details
          </Typography>
        </Box>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          mt="24px"
          sx={{
            '&  .MuiTextField-root': { overflow: 'inherit' },
            '& .MuiFilledInput-root': {
              border: `1px solid ${theme.palette.text.primary}`,
              borderRadius: '10px',
              backgroundColor: '#fff',
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <Input name="visitorName" label="Full name" type="text" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input name="visitorCompany" label="Company" type="text" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input name="visitorEmail" label="Email" type="text" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input name="visitorPhone" label="Phone number" type="text" />
          </Grid>
        </Grid>

        <BookingOptions
          icon={PinIcon}
          title="Location"
          category="location"
          options={SPACES}
        />
        <BookingOptions
          icon={CalendarIcon}
          title="When for?"
          category="month"
          options={MONTHS}
        />
        <BookingOptions
          options={getDaysArray(values.month)}
          category="day"
          setIsDisabledChoice={() => setIsDisabledChoice}
        />
        <TimeField setIsDisabledChoice={() => setIsDisabledChoice} />
        <Divider
          sx={{
            marginTop: '56px',
            marginBottom: '24px',
          }}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={
            isDisabledChoice ||
            (initialValues?.visitorName ? false : !isDirty) ||
            Object.keys(formState.errors).length > 0 ||
            (pastVisitor ? false : !watchFields)
          }
          sx={{
            width: '200px',
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          {buttonText}
        </Button>
        {isDisabledChoice && (
          <FormHelperText error sx={{ textAlign: 'left' }}>
            Either date or time are disabled. Change search criteria.
          </FormHelperText>
        )}
      </form>
    </FormProvider>
  );
};

VisitorForm.defaultProps = {
  initialValues: {},
  isSuccess: true,
  setShowToast: { type: null, open: false },
  pastVisitor: false,
};

export default VisitorForm;
