import React from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { NavigationButton } from 'components/Slider/style';
import NavigationIcon from 'assets/icons/NavigationIcon';
import DatePicker, { DatePickerProps } from './DatePicker';

type CalendarNavigationProps = DatePickerProps & {
  isPartTimeBooking: boolean;
};

const CalendarNavigation = ({
  selectedDate,
  setSelectedDate,
  isPartTimeBooking,
}: CalendarNavigationProps) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const date = moment(selectedDate);
  const dateUnit = isPartTimeBooking ? 'month' : 'day';

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="10px"
      sx={{
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
        },
      }}
    >
      <Typography
        variant="h2"
        minWidth={isPartTimeBooking ? '215px' : '415px'}
        sx={{
          [theme.breakpoints.down('md')]: {
            minWidth: 'auto',
          },
        }}
      >
        {isPartTimeBooking
          ? date.format('MMMM YYYY')
          : date.format('dddd Do MMMM YYYY')}
      </Typography>
      <Box display="flex">
        <DatePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          showOnlyMonth={!!isPartTimeBooking}
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: 'blue.900',
            mx: '12px',
          }}
        />
        <NavigationButton
          onClick={() => {
            setSelectedDate(moment(selectedDate).subtract(1, dateUnit));
            if (searchParams.get('capacity') !== null)
              setSearchParams({
                capacity: searchParams.get('capacity') as string,
                spaceId: searchParams.get('spaceId') as string,
                to: moment(searchParams.get('to'))
                  .subtract(1, dateUnit)
                  .toISOString(),
                from: moment(searchParams.get('from'))
                  .subtract(1, dateUnit)
                  .toISOString(),
              });
          }}
          sx={{
            marginRight: '4px',
          }}
        >
          <NavigationIcon
            sx={{
              padding: '7px',
            }}
          />
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setSelectedDate(moment(selectedDate).add(1, dateUnit));
            if (searchParams.get('capacity') !== null)
              setSearchParams({
                capacity: searchParams.get('capacity') as string,
                spaceId: searchParams.get('spaceId') as string,
                to: moment(searchParams.get('to'))
                  .add(1, dateUnit)
                  .toISOString(),
                from: moment(searchParams.get('from'))
                  .add(1, dateUnit)
                  .toISOString(),
              });
          }}
        >
          <NavigationIcon
            sx={{
              padding: '7px',
              transform: 'rotate(180deg)',
            }}
          />
        </NavigationButton>
      </Box>
    </Box>
  );
};

export default CalendarNavigation;
