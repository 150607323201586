import { Box, Button, IconButton, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';

export const NAVBAR_HEIGHT = '64px';

const NavigationBar = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top={0}
      width="100%"
      height={NAVBAR_HEIGHT}
      sx={{
        zIndex: theme.zIndex.appBar,
        px: 3,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }}
    >
      <Button
        onClick={() => navigate(routes.main.dashboard)}
        sx={{
          cursor: 'pointer',
          padding: 0,
        }}
      >
        <img src="/small-logo.svg" alt="logo" />
      </Button>

      <IconButton
        onClick={toggleDrawer}
        sx={{
          color: '#fff',
        }}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

export default NavigationBar;
