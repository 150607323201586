import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { breakpoint } from 'styles/breakpoints';
import Banner from './Common/Banner';

export type AdBannerProps = {
  image?: string;
  type: string;
  title: string;
  subtitle: string;
  label: string;
  noTopMargin?: boolean;
  onClick: () => void;
};

const AdBanner = ({
  image,
  type,
  title,
  subtitle,
  label,
  noTopMargin,
  onClick,
}: AdBannerProps) => {
  const theme = useTheme();

  const isSmallerResolution = useMediaQuery(breakpoint.lg);

  return (
    <Banner
      color={
        type === 'availability'
          ? theme.palette.primary.main
          : theme.palette.secondary.main
      }
      radius={type === 'slack' ? '84px' : '100px'}
      label={label}
      type={type}
      noTopMargin={noTopMargin}
    >
      <Box
        display="flex"
        width="120px"
        minWidth={type === 'office' ? '100px' : 'auto'}
        minHeight="88px"
        justifyContent="center"
      >
        {image && <img src={image} alt={type} />}
      </Box>
      <Box>
        <Typography
          variant="h2"
          sx={{
            lineHeight: '26px',
            marginBottom: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            lineHeight: '18px',
            maxWidth: '500px',
          }}
        >
          {subtitle}
        </Typography>
      </Box>
      {type === 'office' && (
        <Box
          mt="-16px"
          mx="25px"
          alignSelf="flex-start"
          sx={{
            display: isSmallerResolution ? 'block' : 'none',
          }}
        >
          <img src="/light.svg" alt="" />
        </Box>
      )}
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          marginLeft: 'auto',
          backgroundColor: theme.palette.primary.main,
          flexShrink: 0,
          width: '200px',
          justifyContent: 'center',

          [theme.breakpoints.down('md')]: {
            marginInline: 'auto',
          },
        }}
      >
        {label}
      </Button>
    </Banner>
  );
};
AdBanner.defaultProps = {
  image: '',
  noTopMargin: false,
};

export default AdBanner;
