import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Box, Typography, Divider } from '@mui/material';
import { useInfiniteQuery, useQuery } from 'react-query';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';

import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import MessageItem from 'components/Alerts/MessageItem';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import CardItem from 'components/Common/CardItem';
import EmptySection from 'components/Common/EmptySection';
import Loader from 'components/Loader';
import AlertsMenu from 'components/Alerts/AlertsMenu';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import breakpoints from 'styles/theme/breakpoints';
import { LIMITS } from 'constants/common';

const breadcrumbsConfig = () => [{ label: 'Alerts' }];

type PerksAndEventsProps = (PerkProps & EventProps & { type: string })[];

const getAlerts = async ({ pageParam = 1 }) => {
  const { data } = await API.get(
    APIRoutes.alerts.index(LIMITS.ALERTS, pageParam),
  );
  return data;
};

const getPerksAndEvents = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.alerts.perksAndEvents);
  return data;
};

const Alerts = () => {
  const navigate = useNavigate();

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isLoadingAlerts,
    isSuccess: isSuccessAlerts,
  } = useInfiniteQuery(['alerts'], getAlerts, {
    getNextPageParam: (lastPage, pages) =>
      pages.length < lastPage.totalPages ? pages.length + 1 : undefined,
    enabled: true,
  });

  const {
    isLoading,
    isSuccess,
    data: perksAndEvents,
  } = useQuery<PerksAndEventsProps>(['perks-and-events'], () =>
    getPerksAndEvents(),
  );

  if (isLoadingAlerts || isLoading) {
    return <Loader />;
  }

  if (isSuccessAlerts && isSuccess) {
    const totalUnread = data?.pages?.[0]?.totalUnread;
    const notifications = data?.pages?.flatMap((page) => page?.data);
    const groups = notifications?.reduce((prev: any, next: any) => {
      const date = next.date.split('T')[0];
      if (!prev[date]) {
        prev[date] = [];
      }
      prev[date].push(next);
      return prev;
    }, {});

    const groupMessages = Object.keys(groups).map((date) => ({
      date,
      items: groups[date],
    }));

    return (
      <>
        <SectionHeader
          title="Alerts"
          hideBackButton
          path={breadcrumbsConfig()}
          elements={null}
          notification={totalUnread > 0 ? totalUnread : null}
        />

        <Section>
          <Box
            display="flex"
            sx={{
              gap: '28px',
              [`@media (max-width: ${breakpoints.values.xl}px)`]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box flexBasis="62%" position="relative">
              <Box display="flex" justifyContent="space-between" mb="48px">
                <Typography variant="h4" fontWeight="bold">
                  Invoices & Messages
                </Typography>

                {!isEmpty(groupMessages) && <AlertsMenu />}
              </Box>
              <Box>
                {isEmpty(groupMessages) ? (
                  <EmptySection
                    onClick={() => navigate(routes.main.account.invoices)}
                    missing="you have no new invoices or messages"
                    helperText="Check out your upcoming invoices"
                    label="Invoices"
                    icon={
                      <img
                        src="/assets/images/NoMessages.svg"
                        alt="No messages"
                      />
                    }
                  />
                ) : (
                  <>
                    {groupMessages.map((x) => {
                      const date = moment(x.date, 'YYYY-MM-DD');
                      return (
                        <Box key={x.date} mb="48px">
                          <Typography mb="12px" fontWeight="bold">
                            {date.isSame(moment(), 'day')
                              ? 'Today'
                              : date.format('dddd Do MMM')}
                          </Typography>
                          {x.items.map((y: AlertMessage) => (
                            <MessageItem
                              key={y._id}
                              type={y.type}
                              label={y.content}
                              date={x.date}
                              isNew={!y.read}
                              id={y._id}
                              entityId={y.entityId}
                            />
                          ))}
                        </Box>
                      );
                    })}

                    {isFetchingNextPage ? (
                      <LoadingSpinner />
                    ) : (
                      <LoadMoreButton
                        hasNextPage={hasNextPage}
                        onClick={fetchNextPage}
                        hideScrollToTopButton
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flexBasis="38%">
              <Typography variant="h4" fontWeight="bold">
                Perks & Events
              </Typography>
              {isEmpty(perksAndEvents) ? (
                <EmptySection
                  missing="there were no perks and events"
                  icon={
                    <img src="/assets/images/NoTickets.svg" alt="No tickets" />
                  }
                />
              ) : (
                <Box mt="48px">
                  {perksAndEvents?.map((x) => {
                    const isPerk = x.type === 'perks';
                    return (
                      <Box key={x.id} mt="24px">
                        <CardItem
                          type={isPerk ? 'promotions' : 'events'}
                          summary={isPerk ? x.summary : x.shortDescription}
                          title={x.title}
                          id={x.id}
                          created={isPerk ? x.created : x.startDate}
                          imageUrl={x.imageUrl}
                          location={x.address}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>
          <Box textAlign="center" mt="27px">
            <ScrollTopButton />
          </Box>
        </Section>
      </>
    );
  }
  return null;
};

export default Alerts;
