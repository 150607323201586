import { Card, Box, useTheme, Typography, Button } from '@mui/material';

import { useAppContext } from 'context/AppContext';

import CloseIcon from 'assets/icons/CloseIcon';

interface ConfirmationBoxProps {
  icon?: React.ReactNode | null;
  text: string;
  confirmationText?: string;
  cancellationText?: string;
  onClick: () => void;
  maxTextWidth?: string;
}

const ConfirmationBox = ({
  icon,
  text,
  confirmationText,
  cancellationText,
  maxTextWidth,
  onClick,
}: ConfirmationBoxProps) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  const handleClick = () => {
    closeModal();
    onClick();
  };

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '380px',
        minHeight: '380px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: '32px',

        [theme.breakpoints.down('md')]: {
          minWidth: 'auto',
          minHeight: 'auto',
        },
      }}
    >
      <>
        <Box
          position="absolute"
          top="16px"
          right="22px"
          width="22px"
          height="20px"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            cursor: 'pointer',

            '& svg': {
              fontSize: '1rem',
              ml: '3px',
              mt: '2px',
            },
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </Box>
        {icon || (
          <Box
            sx={{
              backgroundColor: theme.palette.background.main,
              width: '87px',
              height: '87px',
              borderRadius: '50%',
              position: 'relative',

              '& span': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '70px',
              },
            }}
          >
            <span>?</span>
          </Box>
        )}
        <Typography
          sx={{
            color: theme.palette.text.primary,
            maxWidth: maxTextWidth || '316px',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '26px',
            fontSize: '22px',
            mt: '24px',
          }}
        >
          {text}
        </Typography>
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            justifyContent: 'center',
            fontWeight: 'bold',
            backgroundColor: theme.palette.background.main,
            width: '200px',
            mt: '30px',
            mb: '12px',
          }}
        >
          {confirmationText}
        </Button>
        <Button
          onClick={closeModal}
          variant="contained"
          sx={{
            justifyContent: 'center',
            fontWeight: 'bold',
            backgroundColor: theme.palette.secondary.main,
            color: '#000',
            width: '200px',

            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          {cancellationText}
        </Button>
      </>
    </Card>
  );
};

ConfirmationBox.defaultProps = {
  icon: null,
  confirmationText: 'Yes',
  cancellationText: 'No',
  maxTextWidth: '',
};

export default ConfirmationBox;
