/* eslint-disable no-nested-ternary */
import { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Divider, FormHelperText, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LoadingButton from 'components/Common/LoadingButton';
import BookingOptions from 'components/Rooms/BookingsOptions';
import { MONTHS, CAPACITY, getDaysArray } from 'components/Rooms/helpers';

import PinIcon from 'assets/icons/PinIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import AccountIcon from 'assets/icons/AccountIcon';
import TrashIcon from 'assets/icons/Trash';

import { useAuth } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';

import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';

type Payload = {
  checkIn: string;
  checkOut: string;
  isPartTimeBooking: boolean;
};

type FormTypes = Query & {
  defaultValues: any;
};

const SearchForm = ({
  initialValues,
  isBooked = false,
  setLocalization,
}: {
  initialValues?: Query;
  isBooked?: boolean;
  setLocalization: any;
}) => {
  const [searchParams] = useSearchParams();
  const { currentSpaceId, user, spaces: _spaces, isAuthenticated } = useAuth();
  const { snack, setSnack } = useToast();
  const { closeModal } = useAppContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const spaces = _spaces.length
    ? _spaces
    : queryClient.getQueryData<SpaceProps[]>('spaces') || [];

  const [isDisabledChoice, setIsDisabledChoice] = useState(false);

  const { mutate: editBooking, isLoading: isEditing } = useMutation(
    (data: Payload) =>
      API.put(APIRoutes.bookings.indexById(initialValues?.bookingId), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('booking');
        setSnack({
          ...snack,
          message: 'Booking updated',
          open: true,
          type: 'success',
        });
        closeModal();
      },
      onError: (err: { message: string }) => {
        setSnack({
          ...snack,
          message: err?.message || 'Error',
          open: true,
          type: 'error',
        });
      },
    },
  );

  const { mutate: cancelBooking, isLoading: isCanceling } = useMutation(
    () => API.delete(APIRoutes.bookings.indexById(initialValues?.bookingId)),
    {
      onSuccess: () => {
        closeModal();
        setSnack({
          ...snack,
          message: 'Booking canceled',
          open: true,
          type: 'success',
        });
        navigate(
          routes.main.officeDayBookingsGallery({
            space:
              currentSpaceId === 0 ? user?.spaceId.toString() : currentSpaceId,
          }),
        );
      },
      onError: (err: { message: string }) => {
        closeModal();
        setSnack({
          ...snack,
          message: err?.message || 'Error',
          open: true,
          type: 'error',
        });
      },
    },
  );

  const SPACES = spaces
    .filter((location) => location.id !== 0)
    .map((location) => ({
      label: location.shortName,
      value: location.id.toString(),
    }));

  const defautlLocation = useMemo(() => {
    if (currentSpaceId === 0) {
      return user?.spaceId.toString();
    }

    return currentSpaceId.toString();
  }, [user, currentSpaceId]);

  const defaultValues = useMemo(
    () => ({
      location: initialValues?.location
        ? initialValues?.location
        : searchParams.get('spaceId') || defautlLocation,
      month: initialValues?.month
        ? initialValues?.month
        : moment().startOf('month').toString(),
      day: initialValues?.day
        ? initialValues?.day
        : moment().startOf('day').toString(),
      capacity: initialValues?.capacity ? initialValues?.capacity : '1',
    }),
    [currentSpaceId],
  );

  const methods = useForm<FormTypes>({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(
      yup.object({
        location: yup.string().nullable().required(),
        month: yup.date().nullable().required(),
        day: yup.date().nullable().required(),
        capacity: yup.string().nullable().required(),
      }),
    ),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = methods;
  const values = watch();

  useEffect(() => {
    const dayAdjustedToMonth = moment(values.day)
      .year(moment(values.month).year())
      .month(moment(values.month).month())
      .date(moment(values.day).date());

    setValue('day', moment(dayAdjustedToMonth).toString());
  }, [values.month, values.day]);

  const onSubmit: SubmitHandler<Query> = async (data) => {
    if (data.day && data.month) {
      const startDate = moment()
        .startOf('day')
        .set({
          date: moment(new Date(data.day)).date(),
          month: moment(new Date(data.month)).month(),
          year: moment(new Date(data.month)).year(),
          hours: 8,
          minutes: 30,
        });
      const endDate = startDate.clone().set({
        hours: 17,
        minutes: 30,
      });

      if (isBooked) {
        const payload = {
          checkIn: startDate.toISOString(),
          checkOut: endDate.toISOString(),
          isPartTimeBooking: true,
        };
        editBooking(payload);
      } else {
        const query = {
          from: startDate.toISOString(),
          to: endDate.toISOString(),
          capacity: data.capacity,
          spaceId: data.location,
        };
        closeModal();
        if (setLocalization !== null) {
          setLocalization(parseInt(data.location as string, 10));
        }
        if (isAuthenticated) {
          navigate(routes.main.officeDayBookingsGallery(query));
        } else {
          navigate(routes.main.externalOfficeDayBookingsGallery(query));
        }
      }
    }
  };

  const handleButtonText = useMemo(() => {
    if (isBooked) return 'Edit booking';
    if (!isEmpty(initialValues)) return 'Save';

    return 'See rooms';
  }, [isBooked, initialValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isBooked && (
          <BookingOptions
            icon={PinIcon}
            title="Location"
            category="location"
            options={SPACES}
          />
        )}
        <BookingOptions
          icon={CalendarIcon}
          title="When for?"
          category="month"
          options={MONTHS}
        />
        <BookingOptions
          options={getDaysArray(values.month)}
          category="day"
          setIsDisabledChoice={() => setIsDisabledChoice}
        />
        {!isBooked && (
          <BookingOptions
            icon={AccountIcon}
            title="How many people?"
            category="capacity"
            options={CAPACITY}
          />
        )}
        <Divider
          sx={{
            marginTop: '56px',
            marginBottom: '24px',
          }}
        />
        <Box
          display="flex"
          gap="16px"
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Tooltip
            title={
              !user?.canMakeBookings && isBooked
                ? 'You cannot make bookings. Contact with your Team Leader.'
                : !isValid
                ? 'All of the information above is needed.'
                : null
            }
          >
            <span>
              <LoadingButton
                isLoading={isEditing}
                variant="contained"
                type="submit"
                disabled={
                  !isValid ||
                  isDisabledChoice ||
                  (!user?.canMakeBookings && isBooked)
                }
                sx={{
                  width: '200px',
                  height: '48px',
                  backgroundColor: theme.palette.primary.main,
                  justifyContent: 'center',
                }}
              >
                {handleButtonText}
              </LoadingButton>
            </span>
          </Tooltip>
          {isBooked && (
            <LoadingButton
              isLoading={isCanceling}
              variant="contained"
              color="secondary"
              disabled={isDisabledChoice}
              sx={{
                width: '200px',
                height: '48px',
              }}
              endIcon={<TrashIcon />}
              onClick={() => cancelBooking()}
            >
              Cancel booking
            </LoadingButton>
          )}
        </Box>
        {isDisabledChoice && (
          <FormHelperText error sx={{ textAlign: 'left' }}>
            Either date or time are disabled. Change search criteria.
          </FormHelperText>
        )}
      </form>
    </FormProvider>
  );
};

SearchForm.defaultProps = {
  initialValues: {},
  isBooked: false,
};

export default SearchForm;
