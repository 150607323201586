import { Avatar, Box, CardMedia, Typography, useTheme } from '@mui/material';
import { useQuery } from 'react-query';

import TeamMemberIcon from 'assets/icons/TeamMemberIcon';
import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import AccountCard from './AccountCard';

type CardTeamProps = {
  label: string;
  wide?: boolean;
};

const CardMyTeam = ({ label, wide }: CardTeamProps) => {
  const theme = useTheme();
  const getTeamDetails = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.teams.index);
    return data;
  };

  const { isLoading, data: team } = useQuery<TeamProps>(['team'], () =>
    getTeamDetails(),
  );

  return (
    <AccountCard
      label={label}
      value={routes.main.account.team}
      wide={wide}
      elements={
        isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box
            display="flex"
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: '8px',
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              borderRight="1px solid #D9DFEF"
              paddingRight="32px"
              marginRight="32px"
              sx={{
                [theme.breakpoints.down('md')]: {
                  border: 'none',
                },
              }}
            >
              <CardMedia
                component="img"
                src={team?.avatar}
                onError={(
                  event: React.SyntheticEvent<HTMLImageElement, Event>,
                ) => {
                  event.currentTarget.src = '/assets/images/placeholder.png';
                }}
                sx={{
                  width: '229px',
                  height: '133px',
                  marginBottom: '16px',
                }}
              />
              <Typography fontWeight="700" fontSize="20px" my="0">
                {team?.name}
              </Typography>
              <Typography my="0">{team?.website}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              flexWrap="wrap"
              width="100%"
            >
              {team?.teamMembers?.map((teamMember) => (
                <Box
                  key={teamMember.id}
                  display="flex"
                  flexBasis="33%"
                  alignItems="center"
                  gap="8px"
                  my="12px"
                  paddingRight="32px"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexBasis: '100%',
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: 62,
                      height: 62,
                      backgroundColor: 'transparent',
                    }}
                  >
                    {teamMember.avatar ? (
                      <Avatar
                        src={teamMember.avatar}
                        sx={{ width: 62, height: 62 }}
                      />
                    ) : (
                      <TeamMemberIcon sx={{ width: 62, height: 62 }} />
                    )}
                  </Avatar>
                  <Typography fontWeight="700">
                    {teamMember.fullName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
    />
  );
};

CardMyTeam.defaultProps = {
  wide: false,
};

export default CardMyTeam;
