import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import CustomChip from 'components/Common/CustomChip';

type AnnouncementCardModalProps = AnnouncementProps;

const AnnouncementCardModal = ({
  name,
  date,
  content,
  space,
}: AnnouncementCardModalProps) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        overflowY: 'auto',
        maxWidth: '560px',
        maxHeight: 'calc(100dvh - 100px)',

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <Box position="sticky" top="0px" zIndex="101" bgcolor="background.card">
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              sx={{
                position: 'absolute',
                top: '25px',
                right: '14px',
                width: '20px',
                height: '20px',
                background: theme.palette.secondary.main,
                '&:hover': {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <CloseIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  color: theme.palette.blue[800],
                }}
              />
            </IconButton>
          }
          title={
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '5px',
                }}
              >
                <Typography
                  sx={{ fontSize: 'smallFontSize', opacity: 0.65 }}
                  color="text.content"
                >
                  {date}
                </Typography>
                <CustomChip
                  label={space.replace('Work.Life ', '')}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    fontSize: 'smallFontSize',
                    fontWeight: 'fontWeightRegular',
                    cursor: 'inherit',
                    marginRight: '5px',
                  }}
                />
              </Box>
              <Typography variant="h2">{name}</Typography>
            </>
          }
          titleTypographyProps={{
            variant: 'h2',
          }}
          sx={{
            padding: '25px 40px 25px 40px',
          }}
        />
        <Divider />
      </Box>
      <CardContent
        sx={{
          padding: '24px 32px 24px 32px',
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
            a: {
              color: 'inherit',
            },
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </CardContent>
    </Card>
  );
};

export default AnnouncementCardModal;
