import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BANNER } from 'constants/banner';
import Banner from './Common/Banner';

const AppBanner = () => {
  const theme = useTheme();

  return (
    <Banner
      color={theme.palette.secondary.main}
      radius="100px"
      label={BANNER.APP.label}
      type={BANNER.APP.type}
      noTopMargin={false}
    >
      <Box
        display="flex"
        width="120px"
        minWidth="auto"
        minHeight="88px"
        justifyContent="center"
      >
        <img src={BANNER.APP.image} alt={BANNER.APP.type} />
      </Box>
      <Box>
        <Typography
          variant="h2"
          sx={{
            lineHeight: '26px',
            marginBottom: '8px',
          }}
        >
          {BANNER.APP.title}
        </Typography>
        <Typography
          sx={{
            lineHeight: '18px',
            maxWidth: '500px',
          }}
        >
          {BANNER.APP.text}
        </Typography>
      </Box>
      <Box
        marginLeft="auto"
        flexShrink={0}
        display="flex"
        gap="16px"
        sx={{
          [theme.breakpoints.down('md')]: {
            marginInline: 'auto',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          component={Link}
          href="https://play.google.com/store/apps/details?id=life.work.app"
          target="_blank"
          width="140px"
          textAlign="center"
          underline="none"
        >
          <img src="/android-app-qr.png" alt="android-app-qr" width="100%" />
          <Typography fontWeight={theme.typography.fontWeightBold}>
            Android
          </Typography>
        </Box>
        <Box
          component={Link}
          href="https://apps.apple.com/pl/app/work-life/id1541199437"
          target="_blank"
          width="140px"
          textAlign="center"
          underline="none"
        >
          <img src="/ios-app-qr.png" alt="ios-app-qr" width="100%" />
          <Typography fontWeight={theme.typography.fontWeightBold}>
            iOS
          </Typography>
        </Box>
      </Box>
    </Banner>
  );
};

export default AppBanner;
