import React, { ChangeEvent } from 'react';
import { TextField, FormControl, MenuItem, useTheme } from '@mui/material';

export const CancellationReason = ({
  options,
  currentReason,
  setCurrentReason,
}: {
  options: {
    label: string;
    value: string;
  }[];
  currentReason: string;
  setCurrentReason: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();

  return (
    <FormControl
      sx={{
        width: 450,
        margin: 'auto',

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    >
      <TextField
        label="Cancellation Reason"
        value={currentReason || ''}
        select
        required
        fullWidth
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setCurrentReason(event.target.value);
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              fontSize: '16px',
              whiteSpace: 'normal',
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
