import {
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';

type ActionCardProps = {
  title: string;
  description: string;
  image: string;
  label: string;
  link: string;
};

const TicketCard = ({
  title,
  description,
  image,
  label,
  link,
}: ActionCardProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: '0px',
        marginBottom: '32px',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          padding: '24px 32px',
          justifyContent: 'space-between',

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box>
          <Typography
            variant="h4"
            fontWeight={theme.typography.fontWeightBold}
            marginBottom="12px"
            lineHeight="18px"
          >
            {title}
          </Typography>
          <Typography lineHeight="18px" marginBottom="12px" maxWidth="426px">
            {description}
          </Typography>
          <Button
            component={Link}
            to={link}
            variant="contained"
            color="secondary"
            sx={{
              justifyContent: 'center',
              width: '144px',
              height: '44px',
              '&:hover': {
                color: theme.palette.blue[900],
              },
            }}
          >
            {label}
          </Button>
        </Box>
        <Box>
          <img src={image} alt={`${title}-img`} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TicketCard;
