import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { get } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import routes from 'utils/routes';
import UploadFormsButton from 'components/Home/OnboardingModal/UploadFormsButton';
import DownloadInvoiceButton from 'components/Home/OnboardingModal/DownloadInvoiceButton';

type TasksListProps = {
  tasks: Tasks;
  closeModal: () => void;
};

const TasksList = ({ tasks, closeModal }: TasksListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const steps = useMemo(
    () => [
      {
        title: 'Pay your security deposit',
        subtitle:
          'you can download your invoice to find the total and payment instruction',
        accessorKey: 'isSecurityDepositPaid',
        buttonComponent: (
          <DownloadInvoiceButton
            invoice={tasks.isSecurityDepositPaid?.invoice}
          />
        ),
      },
      {
        title: 'Submit logo',
        subtitle:
          'please use a high resolution image - this is for your office door!',
        accessorKey: 'isLogoSubmitted',
        button: {
          label: 'Submit',
          onClick: () => {
            closeModal();
            navigate(routes.main.account.team);
          },
        },
      },
      {
        title: 'Submit your Letter of Authority',
        subtitle: 'so we can pay your business rates on your behalf',
        accessorKey: 'isLOAUploaded',
        buttonComponent: <UploadFormsButton />,
      },
      {
        title: 'Set up a direct debit',
        subtitle: 'this is how you will pay your ongoing monthly invoices',
        accessorKey: 'isDirectDebitActive',
        button: {
          label: 'Set up',
          onClick: () => {
            closeModal();
            navigate(`${routes.main.account.profile}#payment`);
          },
        },
      },
      {
        title: 'Invite your team',
        subtitle:
          'plan their first day so we can show them around the space and give them an induction',
        accessorKey: 'isTeamMembersInvited',
        button: {
          label: 'Invite',
          onClick: () => {
            closeModal();
            navigate(routes.main.account.team, {
              state: { shouldOpenModal: true },
            });
          },
        },
      },
    ],
    [closeModal, navigate],
  );

  return (
    <Stack
      divider={<Divider />}
      bgcolor="background.card"
      sx={{
        padding: '6px 24px',
        borderRadius: '8px',
      }}
    >
      {steps.map(
        ({ accessorKey, title, subtitle, button, buttonComponent }) => {
          const task = get(tasks, accessorKey);

          return (
            <Stack
              key={accessorKey}
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 0px',

                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                },
              }}
            >
              <Box width="100%">
                <Typography variant="h5" fontWeight={700}>
                  {title}
                </Typography>
                <Typography fontSize={13}>{subtitle}</Typography>
              </Box>
              <Box
                sx={{
                  width: '105px',
                  height: '46px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {task?.completed ? (
                  <ToastSuccessIcon />
                ) : (
                  buttonComponent || (
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{
                        justifyContent: 'center',
                      }}
                      onClick={() => button.onClick()}
                    >
                      {button.label}
                    </Button>
                  )
                )}
              </Box>
            </Stack>
          );
        },
      )}
    </Stack>
  );
};

export default TasksList;
