import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { isEmpty, partition } from 'lodash-es';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, Grid, Stack, useTheme } from '@mui/material';

import CalendarItem from 'components/Bookings/CalendarItem';
import { Title } from 'components/Title';
import Loader from 'components/Loader';
import BookingCard from 'components/Bookings/BookingCard';
import Section from 'components/Section';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import ScrollTopButton from 'components/Common/ScrollTopButton';
import EmptySection from 'components/Common/EmptySection';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

import { API, APIRoutes } from 'utils/api';

import SadFace from 'assets/icons/SadFace';

import { useAppContext } from 'context/AppContext';
import CurrentBookingItem from 'components/Bookings/CurrentBookingItem';
import HeaderSection from './ResultsViewHeader';

interface GalleryProps {
  changeLoc: (value: number) => void;
  loc: number | undefined;
  extendedGrid: boolean;
  setExtendedGrid: React.Dispatch<React.SetStateAction<boolean>>;
  isPartTimeBooking: boolean;
}

interface Params {
  spaceId: number | undefined;
  capacity: number;
  from: string;
  to: string;
  isPartTimeBooking: boolean;
}

const getBookings = async (isPartTimeBooking: boolean) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.my({ isPartTimeBooking }));
  return data;
};

const getRooms = async (params: Params) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.filtered(params));
  return data;
};

const GridRoomsView = ({
  RoomsIsLoading,
  RoomsData,
  defaultParams,
  extendedGrid,
  isPartTimeBooking,
}: {
  RoomsIsLoading: boolean;
  RoomsData: Room[] | undefined;
  defaultParams: Params;
  extendedGrid: boolean;
  isPartTimeBooking: boolean;
}) => {
  const { openModal } = useAppContext();

  if (RoomsIsLoading) {
    return <LoadingSpinner minHeight="150px" />;
  }

  if (isEmpty(RoomsData)) {
    return (
      <EmptySection
        missing={`there were no ${extendedGrid ? 'results' : 'rooms'} found`}
        label={extendedGrid ? 'Edit search' : 'Book a room'}
        helperText={
          extendedGrid ? 'Try adjusting your search' : 'Book a room today'
        }
        icon={<SadFace />}
        isEdit={extendedGrid}
        onClick={() => {
          openModal(
            <MeetingRoomModal
              initialValues={{
                location: defaultParams?.spaceId?.toString(),
              }}
              isPartTimeBooking={isPartTimeBooking}
            />,
          );
        }}
      />
    );
  }

  return (
    <Grid container spacing={2} mt={extendedGrid ? '0px' : '26px'}>
      {RoomsData?.filter((card) => !card.hidden).map((card: Room) => {
        const from = isPartTimeBooking ? card.startDate : defaultParams?.from;
        const fromTime = moment(from).format('HH:mm');

        const to = isPartTimeBooking ? card.endDate : defaultParams?.to;
        const toTime = moment(to).format('HH:mm');

        return (
          <Grid item xs={12} md={extendedGrid ? 4 : 6} key={card.id}>
            <BookingCard
              card={card}
              from={fromTime}
              to={toTime}
              space={defaultParams?.spaceId}
              bookingInterval={{
                from,
                to,
              }}
              extendedGrid={extendedGrid}
              isPartTimeBooking={card.isPartTimeBooking}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const Gallery = ({
  changeLoc,
  loc,
  extendedGrid,
  setExtendedGrid,
  isPartTimeBooking,
}: GalleryProps) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { openModal } = useAppContext();

  const OneHourAdded = moment()
    .add(1, 'hours')
    .set({
      minutes: moment().get('minutes') - (moment().get('minutes') % 15) + 15,
      seconds: 0,
      milliseconds: 0,
    });

  const paramsSpaceId = searchParams.get('spaceId') as string;
  const paramFrom = searchParams.get('from') as string;
  const paramTo = searchParams.get('to') as string;
  const paramCapacity = searchParams.get('capacity') as string;

  const defaultStartDate = isPartTimeBooking
    ? moment().startOf('day').set({ hours: 8, minutes: 30 })
    : moment().set({
        minutes: moment().get('minutes') - (moment().get('minutes') % 15) + 15,
        seconds: 0,
        milliseconds: 0,
      });
  const defaultEndDate = isPartTimeBooking
    ? moment().startOf('day').set({ hours: 17, minutes: 30 })
    : OneHourAdded;

  const defaultParams: Params = {
    spaceId: +paramsSpaceId,
    capacity: Number.isNaN(parseInt(paramCapacity, 10))
      ? 1
      : parseInt(paramCapacity, 10),
    from: (paramFrom ? moment(paramFrom) : defaultStartDate).toISOString(),
    to: (paramTo ? moment(paramTo) : defaultEndDate).toISOString(),
    isPartTimeBooking,
  };

  const {
    isLoading: BookingsIsLoading,
    data: bookingsData,
    isSuccess: isSuccessBookings,
  } = useQuery<BookingProps[]>(['bookings', { isPartTimeBooking }], () =>
    getBookings(isPartTimeBooking),
  );

  const {
    isLoading: RoomsIsLoading,
    data: RoomsData,
    isSuccess,
  } = useQuery<Room[]>(
    [
      'rooms',
      { isPartTimeBooking },
      [
        searchParams.get('spaceId'),
        searchParams.get('capacity'),
        searchParams.get('from'),
        searchParams.get('to'),
      ],
    ],
    () => getRooms(defaultParams),
    {
      enabled: !Number.isNaN(defaultParams.spaceId),
    },
  );

  useEffect(() => {
    if (isSuccess) {
      setExtendedGrid(searchParams.get('from') !== null);

      if (
        searchParams.get('from') !== null &&
        loc === parseInt(searchParams.get('spaceId') as string, 10)
      )
        changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
    }
  }, [RoomsData]);

  useEffect(() => {
    if (extendedGrid)
      changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
  }, [isSuccess]);

  const [currentBookings, upcomingBookings] = useMemo(() => {
    const bookings = bookingsData?.filter((booking) =>
      moment(booking.checkOut).isSameOrAfter(moment()),
    );

    return partition(bookings, (booking) =>
      moment(booking.checkIn).isBefore(moment()),
    );
  }, [bookingsData]);

  if (BookingsIsLoading && RoomsIsLoading) {
    return <Loader />;
  }

  return (
    <Section>
      <Box
        sx={{
          display: 'flex',
          gap: '28px',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <>
          {!extendedGrid && (
            <Box
              width="100%"
              sx={{
                maxWidth: '379px',
                [theme.breakpoints.down('md')]: {
                  maxWidth: 'none',
                },
              }}
            >
              {(() => {
                if (isSuccess && isSuccessBookings) {
                  return (
                    <Stack spacing={4}>
                      {currentBookings.length > 0 && (
                        <Stack spacing={2} component="span">
                          <Title variant="h3">Current bookings</Title>
                          {currentBookings?.map((el) => (
                            <CurrentBookingItem
                              key={`upcoming_bookings-${el._id}`}
                              {...el}
                              isPartTimeBooking={isPartTimeBooking}
                            />
                          ))}
                        </Stack>
                      )}

                      <Box>
                        <Title variant="h3">Upcoming bookings</Title>
                        <Box component="span">
                          {!upcomingBookings?.length ? (
                            <EmptySection
                              missing="you have no upcoming bookings"
                              label="Book a room"
                              helperText="Book a room today"
                              icon={<SadFace />}
                              onClick={() => {
                                openModal(
                                  <MeetingRoomModal
                                    setLocalization={changeLoc}
                                    initialValues={{
                                      location:
                                        defaultParams?.spaceId?.toString(),
                                    }}
                                    isPartTimeBooking={isPartTimeBooking}
                                  />,
                                );
                              }}
                            />
                          ) : (
                            upcomingBookings?.map(
                              (el, index: number, arr: any[]) => {
                                const prevElementFromDate = moment(
                                  arr[index - 1]?.checkIn,
                                );
                                const currentElFromDate = moment(el?.checkOut);

                                const isSameMonth = moment(
                                  prevElementFromDate,
                                ).isSame(currentElFromDate, 'month');

                                return (
                                  <Box component="span" key={el._id}>
                                    {upcomingBookings.length > 0 && (
                                      <Typography
                                        mt={isSameMonth ? 2 : 3}
                                        mb="6px"
                                        fontWeight="bold"
                                      >
                                        {!isSameMonth
                                          ? currentElFromDate.format('MMMM')
                                          : ''}
                                      </Typography>
                                    )}
                                    <CalendarItem
                                      key={`upcoming_bookings-${el._id}`}
                                      {...el}
                                      isPartTimeBooking={isPartTimeBooking}
                                    />
                                  </Box>
                                );
                              },
                            )
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  );
                }
                return <LoadingSpinner minHeight="150px" />;
              })()}
            </Box>
          )}
          <Box width="100%">
            <HeaderSection
              startDate={defaultParams.from}
              extendedGrid={extendedGrid}
              loc={loc}
              changeLoc={changeLoc}
              isPartTimeBooking={isPartTimeBooking}
              element={
                <Title variant="h3" mb="27px">
                  Available right now
                </Title>
              }
            />

            <GridRoomsView
              RoomsIsLoading={RoomsIsLoading}
              RoomsData={RoomsData}
              defaultParams={defaultParams}
              extendedGrid={extendedGrid}
              isPartTimeBooking={isPartTimeBooking}
            />
          </Box>
        </>
      </Box>
      <Box textAlign="center" mt="27px">
        <ScrollTopButton />
      </Box>
    </Section>
  );
};
export default Gallery;
