import { useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';

import ConfirmationBox from 'components/Common/ConfirmationBox';
import ImpersonateUserModal from 'components/Impersonate/ImpersonateUserModal';
import { useAppContext } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import routes from 'utils/routes';
import BasketIcon from 'assets/icons/BasketIcon';
import AlertsNotificationIcon from './AlertsNotificationIcon';

export type LinkItem = {
  label: string;
  href: string;
  icon: JSX.Element;
  subHref?: string;
  anonymous: boolean;
  authenticated: boolean;
};

export type NavigationItems = {
  items: LinkItem[];
  isExpanded: boolean;
};

const NavigationList = ({ items, isExpanded }: NavigationItems) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { openModal } = useAppContext();
  const {
    logout,
    currentSpaceId,
    isBasketEmpty,
    user,
    isFromLondon,
    isImpersonation,
    isAuthenticated,
  } = useAuth();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (item: LinkItem) => {
      if (item.label === 'Logout')
        return openModal(
          <ConfirmationBox
            text={
              isBasketEmpty
                ? 'Are you sure you want to logout?'
                : 'You have items in your basket, are you sure you want to logout?'
            }
            onClick={logout}
          />,
        );
      if (item.label === 'Mirror member')
        return openModal(<ImpersonateUserModal />);
      if (item.label === 'Meeting rooms') {
        if (item.authenticated === true) {
          return navigate(
            routes.main.meetingsRoomsCalendar({
              spaceId:
                currentSpaceId === 0
                  ? user?.spaceId.toString()
                  : currentSpaceId,
            }),
          );
        }
        return navigate(
          routes.main.externalBookingsCalendar({
            spaceId: searchParams.get('spaceId') || undefined,
          }),
        );
      }

      if (item.label === 'Office Day Bookings') {
        if (item.authenticated === true) {
          return navigate(
            routes.main.officeDayBookingsCalendar({
              spaceId:
                currentSpaceId === 0
                  ? user?.spaceId.toString()
                  : currentSpaceId,
            }),
          );
        }
        return navigate(
          routes.main.externalOfficeDayBookingsCalendar({
            spaceId: searchParams.get('spaceId') || undefined,
          }),
        );
      }

      if (item.label === 'Desk availability' && !isFromLondon)
        return navigate(routes.main.desks.charts);

      return navigate(item.href);
    },
    [isBasketEmpty, currentSpaceId, isFromLondon, searchParams],
  );

  const getIcon = useCallback(
    (item: LinkItem) => {
      if (item.label === 'Basket') {
        return <BasketIcon hasNotification={!isBasketEmpty} />;
      }
      if (item.label === 'Alerts') {
        return <AlertsNotificationIcon />;
      }
      return item.icon;
    },
    [isBasketEmpty],
  );

  return (
    <List
      sx={{
        px: 2,
        [theme.breakpoints.down('md')]: {
          px: 1,
        },
      }}
    >
      {items
        .filter(
          (item) =>
            !(!user?.canImpersonate && item.label === 'Mirror member') &&
            !(
              !(item.label === 'Logout' || item.label === 'Account') &&
              isAuthenticated &&
              !user?.active &&
              !isImpersonation
            ),
        )
        .map((item) => (
          <ListItem
            key={item.label}
            sx={{ width: isExpanded ? '100%' : 'fit-content' }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleClick(item)}
              selected={
                pathname.split('/')[1] === item.href.split('/')[1] ||
                pathname.split('/')[1] === item.subHref?.split('/')[1]
              }
            >
              <ListItemIcon>{getIcon(item)}</ListItemIcon>
              {isExpanded && (
                <ListItemText primary={item.label} sx={{ minWidth: '126px' }} />
              )}
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

export default NavigationList;
