import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';

type ReadMoreModalProps = {
  planName: string;
  description: string;
};

const ReadMoreModal = ({ planName, description }: ReadMoreModalProps) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        height: '330px',
        overflowY: 'auto',

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <CardHeader
        action={
          <IconButton
            aria-label="close"
            onClick={() => closeModal()}
            sx={{
              position: 'absolute',
              top: '16px',
              right: '14px',
              width: '20px',
              height: '20px',
              background: theme.palette.secondary.main,
              '&:hover': {
                background: theme.palette.secondary.main,
              },
            }}
          >
            <CloseIcon
              sx={{
                width: '14px',
                height: '14px',
                color: theme.palette.blue[800],
              }}
            />
          </IconButton>
        }
        title={planName}
        titleTypographyProps={{
          variant: 'h2',
        }}
        sx={{
          padding: '32px',
        }}
      />
      <Divider />
      <CardContent
        sx={{
          padding: '32px',
        }}
      >
        <Box dangerouslySetInnerHTML={{ __html: description }} />
      </CardContent>
    </Card>
  );
};

export default ReadMoreModal;
