import React from 'react';
import { useQuery } from 'react-query';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import BookingsMetrics from 'pages/Account/Metrics/BookingMetrics';
import { OPTIONS } from 'constants/metrics';

import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import Section from 'components/Section';

import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import { useAuth } from 'context/AuthContext';
import routes from 'utils/routes';
import { Wrapper } from 'pages/Account/Metrics/Wrapper';

const breadcrumbsConfig = () => [{ label: 'Metrics' }];

const fetchMetrics = async () => {
  const { data } = await API.get(APIRoutes.user.metrics);
  return data;
};

const Metrics = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: metricsData,
    isSuccess,
    isLoading,
  } = useQuery<MetricInterface>('metrics', () => fetchMetrics());

  const handleDataSet = (
    type:
      | 'invoicedAmount'
      | 'bookingMinutes'
      | 'checkingMinutes'
      | 'teamInvoicedAmount',
  ) => ({
    labels: metricsData?.data.map((item) =>
      moment(item.monthDate).format('MMM YY'),
    ),
    datasets: [
      {
        data:
          type === 'bookingMinutes' || type === 'checkingMinutes'
            ? metricsData?.data.map((item) => (item[type] / 60).toFixed(2))
            : metricsData?.data.map((item) => item[type]),
        label: 'Me',
        backgroundColor: '#1D1954',
        borderRadius: isMobile ? 4 : 24,
        borderSkipped: false,
        barThickness: isMobile ? 8 : 32,
      },
    ],
    maintainAspectRatio: false,
  });

  const handleOptions = (
    type:
      | 'invoicedAmount'
      | 'bookingMinutes'
      | 'checkingMinutes'
      | 'teamInvoicedAmount',
  ) => ({
    ...OPTIONS,
    plugins: {
      ...OPTIONS.plugins,
      tooltip: {
        ...OPTIONS.plugins.tooltip,
        callbacks: {
          label: (context: any) =>
            type === 'bookingMinutes' || type === 'checkingMinutes'
              ? `${context.dataset.label}: ${context.parsed.y} ${
                  context.parsed.y >= 2 ? 'hours' : 'hour'
                }`
              : `${context.dataset.label}: £${context.parsed.y.toFixed(2)}
                `,
        },
      },
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <SectionHeader
          title="My profile"
          path={breadcrumbsConfig()}
          tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
          elements={
            <Box width="250px">
              <PincodeInput lightBackground />
            </Box>
          }
          backLink={routes.main.account.dashboard}
        />
        <Section>
          {user?.isTeamLeader && <BookingsMetrics />}
          <Wrapper
            label="Payments time in last 12 months"
            withMargins={!!user?.isTeamLeader}
          >
            <Bar
              options={handleOptions(
                user?.isTeamLeader ? 'teamInvoicedAmount' : 'invoicedAmount',
              )}
              data={handleDataSet(
                user?.isTeamLeader ? 'teamInvoicedAmount' : 'invoicedAmount',
              )}
            />
          </Wrapper>
          <Wrapper label="Booked time in last 12 months" withMargins>
            <Bar
              options={handleOptions('bookingMinutes')}
              data={handleDataSet('bookingMinutes')}
            />
          </Wrapper>

          <Wrapper label="Access time in last 12 months">
            <Bar
              options={handleOptions('checkingMinutes')}
              data={handleDataSet('checkingMinutes')}
            />
          </Wrapper>
        </Section>
      </>
    );
  }

  return null;
};

export default Metrics;
