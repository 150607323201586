import { Card, Box, useTheme, Typography, Button } from '@mui/material';
import { includes } from 'lodash-es';
import { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import { CancellationReason } from './CancellationReason';
import { CancellationNotes } from './CancellationNotes';

interface CancellationDialogProps {
  icon?: React.ReactNode | null;
  text: string;
  confirmationText?: string;
  cancellationText?: string;
  onClick: (query: { id: string; notes: string; survey: boolean }) => void;
}

const CancellationDialog = ({
  icon,
  text,
  confirmationText,
  cancellationText,
  onClick,
}: CancellationDialogProps) => {
  const [currentReason, setCurrentReason] = useState('');
  const [notes, setNotes] = useState<string>('');
  const theme = useTheme();
  const { closeModal } = useAppContext();

  const handleClick = () => {
    closeModal();
    onClick({
      id: currentReason,
      notes,
      survey: includes(['Other', 'I didn’t like the workspace'], currentReason),
    });
  };

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '580px',
        minHeight: '380px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: '32px',

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          minWidth: '100%',
          minHeight: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <>
        <Box
          position="absolute"
          top="16px"
          right="22px"
          width="22px"
          height="20px"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            cursor: 'pointer',

            '& svg': {
              fontSize: '1rem',
              ml: '3px',
              mt: '2px',
            },
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </Box>
        {icon || (
          <Box
            sx={{
              backgroundColor: theme.palette.background.main,
              width: '87px',
              height: '87px',
              borderRadius: '50%',
              position: 'relative',

              '& span': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '70px',
              },
            }}
          >
            <span>?</span>
          </Box>
        )}
        <Typography
          sx={{
            color: theme.palette.text.primary,
            maxWidth: '416px',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '20px',
            fontSize: '16px',
            mt: '24px',
            mb: '24px',
          }}
        >
          {text}
        </Typography>
        <CancellationReason
          options={[
            {
              label: 'I upgraded to Unlimited',
              value: 'I upgraded to Unlimited',
            },
            {
              label: 'I upgraded to an office',
              value: 'I upgraded to an office',
            },
            {
              label: 'My company moved away',
              value: 'My company moved away',
            },
            {
              label: 'I have a new job with a new office',
              value: 'I have a new job with a new office',
            },
            {
              label: 'It’s too expensive',
              value: 'It’s too expensive',
            },
            {
              label: 'I wasn’t using it enough',
              value: 'I wasn’t using it enough',
            },
            {
              label: 'I struggled getting a seat',
              value: 'I struggled getting a seat',
            },
            {
              label: 'I didn’t like the workspace',
              value: 'I didn’t like the workspace',
            },
            {
              label:
                'My personal circumstances changed and the location no longer suits me',
              value:
                'My personal circumstances changed and the location no longer suits me',
            },
            {
              label: 'I found a better solution elsewhere',
              value: 'I found a better solution elsewhere',
            },
            {
              label: 'Other',
              value: 'Other',
            },
          ]}
          currentReason={currentReason}
          setCurrentReason={setCurrentReason}
        />
        {(currentReason === 'Other' ||
          currentReason === 'I didn’t like the workspace') && (
          <CancellationNotes
            name="cancellationNotes"
            placeholder="Cancellation notes"
            theme={theme}
            setNotes={setNotes}
          />
        )}
        <Button
          disabled={!currentReason}
          variant="contained"
          onClick={handleClick}
          sx={{
            justifyContent: 'center',
            fontWeight: 'bold',
            backgroundColor: theme.palette.background.main,
            width: '200px',
            mt: '30px',
            mb: '12px',
          }}
        >
          {confirmationText}
        </Button>
        <Button
          onClick={closeModal}
          variant="contained"
          sx={{
            justifyContent: 'center',
            fontWeight: 'bold',
            backgroundColor: theme.palette.secondary.main,
            color: '#000',
            width: '200px',

            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          {cancellationText}
        </Button>
      </>
    </Card>
  );
};

CancellationDialog.defaultProps = {
  icon: null,
  confirmationText: 'Yes',
  cancellationText: 'No',
};

export default CancellationDialog;
