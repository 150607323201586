import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from 'assets/icons/CloseIcon';
import { useAppContext } from 'context/AppContext';
import MotionCard from 'components/MotionCard';
import Chat from './Chat';

const ChatModal = ({ chatId }: { chatId: string }) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  return (
    <MotionCard
      initial={{ x: '100%' }}
      animate={{ x: '0' }}
      transition={{ stiffness: 1000 }}
      sx={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        height: '100%',
        width: '760px',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '18px',
          width: '20px',
          height: '20px',
          background: theme.palette.secondary.main,
          '&:hover': {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <CloseIcon
          sx={{
            width: '14px',
            height: '14px',
            color: theme.palette.blue[800],
          }}
        />
      </IconButton>
      {chatId && <Chat chatId={chatId} />}
    </MotionCard>
  );
};

export default ChatModal;
