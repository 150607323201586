import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormWrapper = styled(Container)(() => ({
  paddingTop: '120px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const FormBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: '20px',
  overflow: 'hidden',
  padding: '32px',
  color: theme.palette.text.primary,
  marginBottom: '32px',
  flex: 1,
}));

export const FormBanner = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: '20px 20px 0px 0px',
  overflow: 'hidden',
  padding: '32px',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '16px',
  },
}));
