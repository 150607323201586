import {
  Box,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from 'assets/icons/CloseIcon';
import { useAppContext } from 'context/AppContext';
import MotionCard from 'components/MotionCard';
import CardActions from './CardActions';

const ProductModal = ({ details }: { details: ProductProps }) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  return (
    <MotionCard
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        overflowY: 'auto',
        maxWidth: '760px',

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '18px',
          width: '20px',
          height: '20px',
          background: theme.palette.secondary.main,
          '&:hover': {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <CloseIcon
          sx={{
            width: '14px',
            height: '14px',
            color: theme.palette.blue[800],
          }}
        />
      </IconButton>
      <CardHeader
        title={<Typography variant="h2">{details.Name}</Typography>}
        sx={{
          padding: '32px',
        }}
      />
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '46px',
          padding: '24px 32px',

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          minWidth="320px"
          sx={{
            [theme.breakpoints.down('md')]: {
              minWidth: 'auto',
            },
          }}
        >
          <CardMedia
            component="img"
            height="189px"
            src={details.imagePath}
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.src = '/assets/images/placeholder.png';
            }}
            alt={`Product ${details.Id} image`}
            sx={{ width: '100%', borderRadius: '4px', marginBottom: '30px' }}
          />
          <CardActions
            productId={details.Id}
            regularCharge={details.RegularCharge}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="16px"
          width="100%"
        >
          <Typography lineHeight="18px" width="100%">
            {details.Description}
          </Typography>
          <Typography
            component={Box}
            variant="h2"
            lineHeight="26px"
            textAlign="right"
          >
            £{details.Price.toFixed(2)}{' '}
            <Typography
              display="inline"
              fontSize={theme.typography.smallFontSize}
              lineHeight="16.25px"
            >
              + VAT
            </Typography>
          </Typography>
        </Box>
      </Box>
    </MotionCard>
  );
};

export default ProductModal;
