import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, last, first, debounce } from 'lodash-es';
import { useAuth } from 'context/AuthContext';

import { API, APIRoutes } from 'utils/api';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import MessageInput from './MessageInput';
import Message from './Message';

const Chat = ({ chatId }: { chatId: string }) => {
  const { spaces } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const messageBoxRef = useRef<HTMLDivElement>();
  const [currentScroll, setCurrentScroll] = useState(-1);

  const getChat = async () => {
    const lastId = last(messages)?._id;
    const {
      data: { data },
    } = await API.get(APIRoutes.help.chatByLastId(chatId, lastId));
    if (!isEmpty(data.messages)) {
      const newMessages = [...data.messages].reverse();
      setMessages([...messages, ...newMessages]);
    }
    return data;
  };

  const { isSuccess, data } = useQuery<ChatProps>(
    `chat-${chatId}`,
    () => getChat(),
    {
      refetchInterval: 5000,
    },
  );

  const getPrevChat = async () => {
    const firstId = first(messages)?._id;
    const {
      data: { data: prev },
    } = await API.get(APIRoutes.help.chatByFirstId(chatId, firstId));
    if (!isEmpty(prev.messages)) {
      const prevMessages = [...prev.messages].reverse();
      setMessages([...prevMessages, ...messages]);
      setCurrentScroll(messageBoxRef.current?.scrollHeight || 0);
    }
    return prev;
  };

  const { isFetching: isFetchingPrevMessages } = useQuery<ChatProps>(
    [`chat-${chatId}-prev`, currentScroll],
    () => getPrevChat(),
    {
      enabled: currentScroll === 0,
    },
  );

  useEffect(() => {
    const debouncedChangeHandler = debounce(
      () => setCurrentScroll(messageBoxRef.current?.scrollTop || 0),
      300,
    );
    messageBoxRef.current?.addEventListener('scroll', debouncedChangeHandler);
    return () => {
      messageBoxRef.current?.removeEventListener(
        'scroll',
        debouncedChangeHandler,
      );
    };
  }, [isSuccess]);

  useEffect(() => {
    if (isFetchingPrevMessages) return;
    const messageBox = messageBoxRef.current;
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight - currentScroll;
    }
  }, [first(messages)]);

  useEffect(() => {
    messageBoxRef.current?.scroll({
      top: messageBoxRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [last(messages)]);

  const renderMainSection = useMemo(() => {
    if (isSuccess) {
      const spaceName =
        spaces.find((location) => location.id === data.spaceId)?.shortName ||
        'Work.Life';

      const firstMessage = {
        _id: data._id,
        text: data.description,
        ts: data.ts,
      };

      return (
        <>
          <CardHeader
            title={
              <Box display="flex" alignItems="end" gap="8px">
                <Avatar src="/assets/images/wl-chat-logo.png" alt="wl-logo" />
                <Typography variant="h2">Team {spaceName}</Typography>
                <Typography>Ticket no. {data.hubspotId}</Typography>
              </Box>
            }
            sx={{
              padding: '16px 32px',
            }}
          />
          <Divider />
          <CardContent
            sx={{
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100dvh - 72px)',
            }}
          >
            {isFetchingPrevMessages && <LoadingSpinner />}
            <Box
              height="100%"
              overflow="auto"
              marginBottom="16px"
              ref={messageBoxRef}
            >
              <>
                {first(messages)?.welcome && <Message {...firstMessage} />}
                {messages.map((message) => (
                  <Message key={message._id} {...message} />
                ))}
              </>
            </Box>
            <MessageInput setMessages={setMessages} chatId={chatId} />
          </CardContent>
        </>
      );
    }

    return <LoadingSpinner minHeight="100%" />;
  }, [isFetchingPrevMessages, messages]);

  return renderMainSection;
};

export default Chat;
