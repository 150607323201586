import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { ReactNode } from 'react';

type DataTableProps = {
  children: ReactNode;
  labels: string[];
};

const DataTable = ({ children, labels }: DataTableProps) => {
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        overflowX: 'auto',
      }}
    >
      <Table
        sx={{
          borderCollapse: 'separate',
          borderSpacing: '0px 16px',
        }}
      >
        {!isEmpty(labels) && (
          <TableHead>
            <TableRow>
              {labels.map((label, index) => (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  sx={{
                    paddingY: '0px',
                    border: 'none',
                  }}
                >
                  <Typography fontWeight={theme.typography.fontWeightBold}>
                    {label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody
          sx={{
            '& > tr': {
              borderRadius: '10px',
              '& > td': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& > td:first-of-type': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              },
              '& > td:last-of-type': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              },
            },
          }}
        >
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
