import { Box, Button, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

import MotionBox from 'components/MotionBox';
import AlertCircle from 'assets/icons/AlertCircle';
import LoadingButton from 'components/Common/LoadingButton';

const FormBanner = ({ isLoading }: { isLoading: boolean }) => {
  const {
    reset,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();

  return (
    <AnimatePresence>
      <MotionBox
        position="sticky"
        bottom="0"
        left="0"
        width="100%"
        padding="14px 21px 11px 27px"
        zIndex="9"
        sx={{
          borderRadius: '16px 16px 0px 0px',
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(250, 190, 121, 0.9)',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              gap: '10px',
            },
          }}
        >
          <AlertCircle
            sx={{
              width: '40px',
              height: '40px',
              color: theme.palette.blue[900],
            }}
          />
          {!isEmpty(errors) && (
            <Box
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.smallFontSize,
                color: 'red',
                button: {
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                  color: 'inherit',
                  textDecoration: 'underline',
                  paddingLeft: 0,
                  paddingRight: 0,
                  minWidth: 'auto',
                },
              }}
            >
              There are some errors in the form.{' '}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
              >
                Scroll
              </Button>{' '}
              to the top to fix these errors before submitting
            </Box>
          )}
          <Box display="flex" alignItems="center" gap="20px">
            <Button
              onClick={() => reset()}
              variant="text"
              sx={{
                color: theme.palette.blue[900],
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.smallFontSize,
              }}
            >
              Cancel changes
            </Button>
            <LoadingButton
              isLoading={isLoading}
              sx={{
                width: '200px',
                height: '48px',
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.main,
              }}
              variant="contained"
              type="submit"
              disabled={!isEmpty(errors)}
            >
              Save changes
            </LoadingButton>
          </Box>
        </Box>
      </MotionBox>
    </AnimatePresence>
  );
};

export default FormBanner;
