/* eslint-disable max-len */
import React from 'react';
import uniqBy from 'lodash-es/uniqBy';

import { useQuery, useMutation } from 'react-query';

import { Box, Typography, useTheme, Tooltip } from '@mui/material';

import LoadingButton from 'components/Common/LoadingButton';
import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import Section from 'components/Section';
import Loader from 'components/Loader';

import AcceptIcon from 'assets/icons/AcceptIcon';
import ChosenPlanIcon from 'assets/icons/ChosenPlanIcon';

import { API, APIRoutes } from 'utils/api';

import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import { ACCOUNT_TYPES } from 'constants/account';
import { useAppContext } from 'context/AppContext';
import CancellationDialog from 'components/CancellationDialog';

const breadcrumbsConfig = () => [{ label: 'Plans & Benefits' }];

const Cell = ({
  children,
  width,
  align,
  withBorders,
}: {
  children: React.ReactNode;
  width: number;
  align: string;
  withBorders?: boolean;
}) => (
  <Box
    sx={{
      flexBasis: `${width}%`,
      textAlign: align,
      fontWeight: 800,
      height: withBorders ? '100%' : 'unset',
      position: 'relative',

      '&:after, &:before': {
        display: withBorders ? 'block' : 'none',
        content: "''",
        width: '1px',
        height: '100%',
        backgroundColor: '#D9DFEF',
        position: 'absolute',
        top: 0,
      },

      '&:after': { right: 0 },
    }}
  >
    {children}
  </Box>
);

Cell.defaultProps = {
  withBorders: false,
};

const Row = ({ items, expiresDate }: { items: any[]; expiresDate?: any }) => (
  <Box display="flex" alignItems="normal" height="45px">
    <Cell width={5} align="left">
      {items[0]}
    </Cell>
    <Cell width={65} align="left">
      {items[1]}{' '}
      {expiresDate && (
        <Typography
          component="span"
          sx={{ fontSize: '14px', fontWeight: '400' }}
        >{` - Expires ${expiresDate}`}</Typography>
      )}
    </Cell>
    <Cell width={15} align="center" withBorders>
      <Typography fontWeight="700">{items[2]}</Typography>
    </Cell>
    <Cell width={15} align="center">
      {items[3]}
    </Cell>
  </Box>
);

Row.defaultProps = {
  expiresDate: null,
};

const fetchPlans = async () => {
  const { data } = await API.get(APIRoutes.user.plans);
  return data;
};

const fetchInvoiceStatus = async () => {
  const { data } = await API.get(APIRoutes.user.canCancelContract);
  return data;
};

const Plans = () => {
  const theme = useTheme();
  const { snack, setSnack } = useToast();

  const { userType, logout, user, isImpersonation } = useAuth();
  const { openModal } = useAppContext();

  const { mutate: cancelContract, isLoading: isLoadingCancel } = useMutation(
    (query: { id: string; notes: string; survey: boolean }) =>
      API.delete(
        APIRoutes.user.cancelContract(
          encodeURIComponent(`${query.id}\n${query.notes}`),
        ),
      ),
    {
      onSuccess: () => {
        logout();
      },
      onSettled: (_, error, { survey }) => {
        const msg = !survey
          ? 'Plan canceled'
          : 'Please take a moment to fill in our survey';
        setSnack({
          ...snack,
          message: error ? 'Unable to cancel plan' : msg,
          open: true,
          type: error ? 'error' : 'success',
        });
        if (survey) {
          setTimeout(() => {
            window.location.href =
              'https://workdotlife.typeform.com/to/Q2j6WAn4';
          }, 1000);
        }
      },
    },
  );

  const { data, isSuccess, isLoading } = useQuery('plans', () => fetchPlans());

  const filter = uniqBy(data?.data?.products, 'name');
  const isFlex = userType === ACCOUNT_TYPES.FLEX;
  const {
    data: invoiceStatus,
    isSuccess: isSuccessStatus,
    isLoading: isLoadingInvoiceStatus,
  } = useQuery('balance', () => fetchInvoiceStatus(), {
    enabled: isFlex,
  });

  const counts = data?.data?.products.reduce(
    (acc: any, value: any) => ({
      ...acc,
      [value.name]: (acc[value.name] || 0) + 1,
    }),
    {},
  );

  const squashedProducts: any = filter.map((y: any) => ({
    ...y,
    price: y.price * counts[y.name],
    quantity: y.quantity * counts[y.name],
  }));

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess && data.data) {
    return (
      <>
        <SectionHeader
          title="My profile"
          path={breadcrumbsConfig()}
          tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
          elements={
            <Box width="250px">
              <PincodeInput lightBackground />
            </Box>
          }
          backLink={routes.main.account.dashboard}
        />

        <Section>
          <Box
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              px: '24px',
              py: '32px',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingBottom="20px"
              sx={{
                borderBottom: '1px solid #D9DFEF',
                '& svg': { fontSize: '2.5rem' },
              }}
            >
              <Typography fontSize="20px" fontWeight="700">
                Your chosen plan:
              </Typography>
              {isFlex ? (
                <img
                  src="/assets/images/FlexPlanIcon.png"
                  alt="flex"
                  width="auto"
                  height="44px"
                />
              ) : (
                <ChosenPlanIcon width="52px" height="44px" />
              )}
            </Box>
            <Typography py="24px" fontSize="20px" fontWeight="700">
              {data.data.activeContracts.length > 0
                ? data.data.activeContracts[0].name
                : '1x NO PLAN MEMBERSHIP'}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: '8px',
                },
              }}
            >
              <Box
                sx={{ '& ul': { pl: '20px' } }}
                dangerouslySetInnerHTML={{
                  __html:
                    data.data.activeContracts.length > 0
                      ? data.data.activeContracts[0].description
                      : 'You are not in a price plan. Please contact your Membership Manager',
                }}
              />
              {isFlex &&
                data.data.activeContracts.length > 0 &&
                (user?.active || (!user?.active && isImpersonation)) && (
                  <Tooltip
                    title={
                      invoiceStatus?.data?.canCancel
                        ? null
                        : 'You must settle any outstanding invoices before you can cancel your membership plan. Please reach out to your membership team directly if you are having any issues with paying.'
                    }
                  >
                    <Box component="span">
                      <LoadingButton
                        disabled={!invoiceStatus?.data?.canCancel}
                        isLoading={
                          isLoadingCancel ||
                          isLoadingInvoiceStatus ||
                          !isSuccessStatus
                        }
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          openModal(
                            <CancellationDialog
                              text="Are you sure you want to cancel your membership? Rejoining a plan later will be subject to availability and set-up fees"
                              confirmationText="Yes, I'm sure"
                              cancellationText="No, take me back"
                              onClick={cancelContract}
                            />,
                          )
                        }
                        sx={{
                          borderRadius: '0px',
                          padding: '10px 48px',
                          fontSize: theme.typography.smallFontSize,
                          fontWeight: theme.typography.fontWeightMedium,
                        }}
                      >
                        Cancel my plan
                      </LoadingButton>
                    </Box>
                  </Tooltip>
                )}
            </Box>
          </Box>
        </Section>
        <Section>
          <Box
            sx={{
              color: theme.palette.text.primary,
              borderBottom: '1px solid #D9DFEF',
              pb: '32px',
            }}
          >
            <Typography variant="h3">Benefits</Typography>
            <Typography mt="16px" maxWidth="560px" mb="32px">
              Bookings will be charged to your account as they end. Any credit
              available at the time the booking ends will be used to pay for it.
            </Typography>

            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  overflowX: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  [theme.breakpoints.down('md')]: {
                    minWidth: '800px',
                  },
                }}
              >
                {(data.data.timePasses.length > 0 ||
                  data.data.extraServices.length > 0 ||
                  data.data.bookingCredits.length > 0) && (
                  <Row items={[null, 'Item', 'Quantity', 'Remaining']} />
                )}
                {data.data.timePasses
                  .concat(data.data.extraServices)
                  .concat(data.data.bookingCredits)
                  .map((x: any) => (
                    <Row
                      key={x.creditName}
                      expiresDate={x.creditDetailsList[0].expireDate}
                      items={[
                        <AcceptIcon />,
                        x.creditName,
                        x.totalUses || x.count || x.totalCredit,
                        x.passesLeft || x.usesLeft || x.remainingCredit,
                      ]}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
          <Box pt="48px">
            <Typography variant="h3">Your add-ons</Typography>
            <Typography mt="16px" mb="32px">
              Billed monthly on your current plan
            </Typography>

            {squashedProducts.length > 0 && (
              <>
                <Row items={[null, 'Item', 'Quantity', 'Cost']} />
                {squashedProducts.map((x: any) => (
                  <Row
                    items={[<AcceptIcon />, x.name, x.quantity, `£${x.price}`]}
                    key={x.id}
                  />
                ))}
              </>
            )}
          </Box>
        </Section>
      </>
    );
  }

  return null;
};
export default Plans;
