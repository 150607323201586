/* eslint-disable import/extensions */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { isEmpty, filter, every } from 'lodash-es';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NavigationIcon from 'assets/icons/NavigationIcon';
import {
  IconBox,
  NavigationBox,
  NavigationButton,
} from 'components/Rooms/style';
import { DEFAULT_STATE } from 'constants/rooms';

import CircleWithPlus from 'assets/icons/PlusWithCircleIcon';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import 'swiper/css';
import routes from 'utils/routes';

enum SLOT_COLOR {
  AVAILABLE = '#F7DFC0',
  NOT_AVAILABLE = '#F1F1F1',
  SELECTED = '#FABE79',
  SELECTED_TIME = '#4537ce',
}

export type State = {
  space?: number;
  name: string;
  id: number;
  from: string;
  to: string;
};

type SliderProps = {
  showLabels: boolean;
  room: Room;
  selected: State;
  setSelected: React.Dispatch<React.SetStateAction<State>>;
  isResultsView: boolean;
  isExternal?: boolean;
  timeIndex: number;
  setTimeIndex: (index: number) => void;
  isPartTimeBooking: boolean;
};

const Slider = ({
  showLabels,
  selected,
  setSelected,
  room,
  isResultsView,
  isExternal,
  timeIndex,
  setTimeIndex,
  isPartTimeBooking,
}: SliderProps) => {
  const [hoverDate, setHoverDate] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const withLabels = isMobile || showLabels;

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const isOptionSelected = useCallback(
    (option: RoomAvailability) => {
      const from = moment(selected?.from);
      const to = moment(selected?.to);
      const optionTime = moment(option.date);
      const isBetween = isPartTimeBooking
        ? optionTime.isBetween(from, to, 'days', '[]')
        : optionTime.isBetween(from, to, undefined, '[)');
      return (isResultsView || selected?.id === room?.id) && isBetween;
    },
    [selected],
  );

  const isValidRange = useCallback(
    (from: string, to: string) => {
      const start = room?.availability?.findIndex((item) => item.date === from);
      const end = room?.availability?.findIndex((item) => item.date === to);
      if (start >= end) return false;

      const timeRange = room?.availability?.slice(start, end + 1);
      return timeRange.every((item) => item.available && !item.booked);
    },
    [room],
  );

  const bookingEnabled = useMemo(() => {
    if (!selected.from || !selected.to) return false;
    const from = moment(selected.from);
    const to = moment(selected.to);
    const slots = filter(room?.availability, (slot) => {
      const slotStart = moment(slot.date);
      return slotStart.isSameOrAfter(from) && slotStart.isBefore(to);
    });
    const allFree = every(slots, (slot) => slot.available && !slot.booked);

    return allFree && (!selected.id || selected.id === room.id);
  }, [selected, room]);

  const setHoveredRange = (e: any, option: RoomAvailability) => {
    if (
      !(option.available && !option.booked) ||
      isEmpty(selected?.from) ||
      !moment(option.date).isAfter(selected?.from)
    )
      return;
    const value =
      e.type === 'mouseleave' ||
      !isValidRange(selected?.from, option.date) ||
      !(option.available && !option.booked)
        ? ''
        : option.date;

    setHoverDate(value);
  };

  const handleClick = useCallback(
    (option: RoomAvailability, nextOption: RoomAvailability) => {
      if (option.available && !option.booked) {
        const to = isPartTimeBooking
          ? moment(nextOption?.endDate).subtract(1, 'days').toISOString()
          : nextOption.date;

        if (
          room?.id === selected?.id &&
          selected?.from &&
          selected?.to &&
          isValidRange(selected?.from, option.date)
        ) {
          setSelected({
            ...selected,
            to,
          });
        } else {
          setHoverDate('');
          if (selected?.id === room?.id && selected.from === option.date) {
            // unselect choice by clicking first time slot again
            setSelected(DEFAULT_STATE);
          } else {
            setSelected({
              space: selected?.space,
              name: room?.name,
              from: option.date,
              to,
              id: room?.id,
            });
          }
        }
      }
    },
    [room, selected],
  );

  useEffect(() => {
    if (swiperRef && !swiperRef.destroyed) {
      swiperRef.slideTo(timeIndex);
    }
  }, [swiperRef, timeIndex]);

  const bookNow = useCallback(() => {
    if (isExternal) {
      navigate(
        (isPartTimeBooking
          ? routes.main.externalOfficeDayBookingsReview
          : routes.main.externalBookingsReview)(
          room.id,
          selected.space,
          selected?.from,
          selected?.to,
        ),
      );
    } else {
      navigate(
        (isPartTimeBooking ? routes.main.officeDayBookings : routes.bookings)(
          room.id,
          selected?.from,
          selected?.to,
        ),
        {
          state: {
            capacity: 1,
            returnToDefaultView: true,
          },
        },
      );
    }
  }, [isExternal, room, selected]);

  return (
    <Box
      sx={{
        minWidth: 0,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: '8px',
        },
      }}
    >
      <Box
        sx={{
          minWidth: 0,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            paddingTop: isExternal ? '48px' : '0',
          },
        }}
      >
        {(!isMobile || !isResultsView) && (
          <NavigationBox
            sx={{
              position: 'static',
              minWidth: '56px',
              width: '56px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isBeginning && (!isResultsView || isExternal) && (
              <NavigationButton onClick={handleLeftClick}>
                <NavigationIcon
                  sx={{
                    padding: '7px',
                  }}
                />
              </NavigationButton>
            )}
          </NavigationBox>
        )}
        <Box display="flex" position="relative" sx={{ minWidth: 0 }}>
          <Swiper
            onSwiper={setSwiperRef}
            mousewheel
            spaceBetween={8}
            slidesPerView="auto"
            slidesPerGroupAuto
            normalizeSlideIndex={false}
            shortSwipes={false}
            threshold={10}
            onInit={(e) => {
              setIsBeginning(e.isBeginning);
              setIsEnd(e.isEnd);
            }}
            onActiveIndexChange={(e) => {
              setIsBeginning(e.isBeginning);
              setIsEnd(e.isEnd);
              setTimeIndex(e.activeIndex);
            }}
            style={{
              paddingLeft: '25px',
              paddingRight: '25px',
              ...(withLabels && {
                paddingTop: '50px',
                marginTop: '-50px',
              }),
            }}
          >
            {room?.availability?.map((option, index, options) => {
              const date = moment(option?.date);
              const time = date.format(isPartTimeBooking ? 'DD.MM' : 'HH:mm');

              const nextOptionDate = (
                isPartTimeBooking
                  ? moment(option.endDate).add(1, 'days')
                  : moment(option.date).add(15, 'minutes')
              ).toISOString();

              const nextOption = options[index + 1] || {
                date: nextOptionDate,
                ...(isPartTimeBooking && { endDate: nextOptionDate }),
                available: true,
                booked: false,
              };

              const isAvailable = option.available && !option.booked;
              const isSelected = isOptionSelected(option);

              const isHovered =
                isAvailable &&
                room.id === selected?.id &&
                moment(option.date).isAfter(selected?.from) &&
                moment(option.date).isSameOrBefore(hoverDate);

              const showSelectedTime = date.isBetween(
                selected.from,
                selected.to,
                isPartTimeBooking ? 'days' : null,
                '[]',
              );

              let slotColor = SLOT_COLOR.AVAILABLE;
              if (!isAvailable) {
                slotColor = SLOT_COLOR.NOT_AVAILABLE;
              } else if (
                (isSelected || isHovered) &&
                (!isExternal || room.id === selected.id || !selected.id)
              ) {
                slotColor = SLOT_COLOR.SELECTED;
              }

              return (
                <SwiperSlide
                  key={`${room.id}-${option.date}`}
                  style={{
                    width: 'auto',
                  }}
                >
                  {withLabels && isExternal && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: isPartTimeBooking ? '-48px' : '-38px',
                        left: isPartTimeBooking ? '0px' : '-22px',
                      }}
                    >
                      <Typography
                        variant="h2"
                        fontSize="14px"
                        fontWeight={
                          time.includes(':00') || showSelectedTime ? 700 : 500
                        }
                        color={showSelectedTime ? SLOT_COLOR.SELECTED_TIME : ''}
                        sx={{
                          lineHeight: '16px',
                          textAlign: 'center',
                        }}
                      >
                        {time}
                        {isPartTimeBooking && (
                          <Typography
                            component="span"
                            sx={{
                              display: 'block',
                              fontSize: '12px',
                            }}
                          >
                            {date.format('ddd')}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    onClick={() =>
                      isResultsView && !isExternal
                        ? {}
                        : handleClick(option, nextOption)
                    }
                    onMouseEnter={(e) => setHoveredRange(e, option)}
                    onMouseLeave={(e) => setHoveredRange(e, option)}
                    sx={{
                      width: '36px',
                      height: '96px',
                      backgroundColor: slotColor,
                      borderRadius: '4px',
                      position: 'relative',
                      transition: 'background 0.3s',
                      cursor: isAvailable ? 'pointer' : 'default',
                      '&:hover': {
                        backgroundColor: isAvailable
                          ? SLOT_COLOR.SELECTED
                          : slotColor,
                        transition: 'background 0.3s',

                        ...(isAvailable && {
                          '& span': { opacity: 1, transition: 'opacity 0.3s' },
                        }),
                      },

                      '& span': {
                        opacity: isHovered ? 1 : 0,
                        transition: 'opacity 0.3s',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',

                        '& svg': { color: '#f9f9f9' },
                      },
                    }}
                  >
                    {(isAvailable || isHovered) &&
                      (!isResultsView || isExternal) && (
                        <span>
                          <CircleWithPlus />
                        </span>
                      )}
                    {isSelected &&
                      isAvailable &&
                      (!isExternal ||
                        selected.id === room.id ||
                        !selected.id) && (
                        <IconBox>
                          <ToastSuccessIcon fill="#F7DFC0" />
                        </IconBox>
                      )}
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
        {isExternal && (
          <NavigationBox
            sx={{
              position: 'static',
              width: '56px',
              minWidth: '56px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isEnd && (
              <NavigationButton onClick={handleRightClick}>
                <NavigationIcon
                  sx={{
                    padding: '7px',
                    transform: 'rotate(180deg)',
                  }}
                />
              </NavigationButton>
            )}
          </NavigationBox>
        )}
        {isResultsView || isExternal ? (
          !isMobile && (
            <Box display="flex" alignItems="center" marginLeft="8px">
              <Button
                variant="contained"
                disabled={!bookingEnabled}
                onClick={() => bookNow()}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  justifyContent: 'center',
                  minWidth: '115px',
                  width: '115px',
                  height: '44px',
                }}
              >
                Book now
              </Button>
            </Box>
          )
        ) : (
          <NavigationBox
            sx={{
              position: 'static',
              width: '56px',
              minWidth: '56px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isEnd && (
              <NavigationButton onClick={handleRightClick}>
                <NavigationIcon
                  sx={{
                    padding: '7px',
                    transform: 'rotate(180deg)',
                  }}
                />
              </NavigationButton>
            )}
          </NavigationBox>
        )}
      </Box>

      {(isResultsView || isExternal) && isMobile && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={!bookingEnabled}
            onClick={() => bookNow()}
            sx={{
              backgroundColor: theme.palette.primary.main,
              justifyContent: 'center',
              minWidth: '115px',
              width: '115px',
              height: '44px',
            }}
          >
            Book now
          </Button>
        </Box>
      )}
    </Box>
  );
};

Slider.defaultProps = { isExternal: false };

export default Slider;
