import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import CloseIcon from 'assets/icons/CloseIcon';
import AsyncAutocomplete from 'components/Form/AsyncAutocomplete';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import LoadingButton from 'components/Common/LoadingButton';
import routes from 'utils/routes';

import { KEYS, setItem } from 'utils/cache';

const schema = yup.object({
  coworker: yup
    .object({
      label: yup.string().ensure().required(),
      value: yup.string().ensure().required(),
    })
    .required('Impersonated user cannot be empty')
    .nullable(),
});
type FormData = yup.InferType<typeof schema>;

const usersAutocompleteHelpers = {
  getLabelFromFetchFunction: (el: Record<string, any>) =>
    `${el.fullName} (${el.email})`,
  getValueFromFetchFunction: (el: Record<string, unknown>) => el.id as number,
  isOptionEqualToValue: (option: any, value: any) =>
    option.value?.id === value.value?.id,
};

const ImpersonateUserModal = () => {
  const theme = useTheme();
  const { closeModal } = useAppContext();
  const { snack, setSnack } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: impersonateMember, isLoading } = useMutation(
    (data: FormData) =>
      API.post(APIRoutes.user.impersonateSelectedUser, {
        coworkerId: +data.coworker.value,
      }),
    {
      onSuccess: (data) => {
        const {
          data: { data: response },
        } = data;
        // refresh & invalidate all Queries
        navigate(routes.main.dashboard, { replace: true });
        navigate(0);
        queryClient.resetQueries();
        setItem(KEYS.IMPERSONATE_TOKEN, response.impersonationToken);
      },
      onSettled: (_, error: any) => {
        closeModal();
        setSnack({
          ...snack,
          message: error
            ? `Error: ${error?.message}`
            : 'Success to log in as impersonated user',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  const getUsers = async (search: string) => {
    const {
      data: { data },
    } = await API.get(APIRoutes.user.usersToImpersonate(search));
    return data;
  };

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      coworker: {
        label: '',
        value: '',
      },
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) =>
    impersonateMember(data);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            overflowY: 'auto',
            maxWidth: '560px',

            [theme.breakpoints.down('md')]: {
              width: '100vw',
              height: '100%',
              top: 0,
              left: 0,
              transform: 'none',
            },
          }}
        >
          <Box
            position="sticky"
            top="0px"
            zIndex="101"
            bgcolor="background.card"
          >
            <CardHeader
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => closeModal()}
                  sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '32px',
                    width: '20px',
                    height: '20px',
                    background: theme.palette.secondary.main,
                    '&:hover': {
                      background: theme.palette.secondary.main,
                    },
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: '14px',
                      height: '14px',
                      color: theme.palette.blue[800],
                    }}
                  />
                </IconButton>
              }
              title={
                <Typography
                  fontSize="26px"
                  fontWeight={theme.typography.fontWeightBold}
                  color={theme.palette.blue[900]}
                >
                  Mirror member
                </Typography>
              }
              sx={{
                padding: '24px 32px 24px 32px',
              }}
            />
            <Divider />
          </Box>
          <CardContent
            sx={{
              padding: '24px 32px 24px 32px',
            }}
          >
            <Box display="flex" gap={2} flexDirection="column">
              <Typography lineHeight="18px">
                This action will sign you in as this customer in the members
                portal. You can enter email of the user you would like to
                impersonate:
              </Typography>
              <AsyncAutocomplete
                label="User to impersonate"
                name="coworker"
                required
                fetchFunction={getUsers}
                {...usersAutocompleteHelpers}
              />
            </Box>
          </CardContent>
          <Divider sx={{ mt: '8px' }} />
          <Box display="flex" sx={{ margin: '32px 32px 44px' }} gap={2}>
            <Button
              onClick={closeModal}
              variant="contained"
              sx={{
                justifyContent: 'center',
                fontWeight: 'bold',
                backgroundColor: theme.palette.secondary.main,
                color: '#000',
                width: '200px',

                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={!isDirty || !isValid}
              isLoading={isLoading}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: '200px',
                height: '48px',
                justifyContent: 'center',
              }}
            >
              Mirror member
            </LoadingButton>
          </Box>
        </Card>
      </form>
    </FormProvider>
  );
};

export default ImpersonateUserModal;
