import { useCallback, useState, useEffect } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Icon, SvgIconProps, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import NavigationIcon from 'assets/icons/NavigationIcon';
import { isOptionDisabled } from 'components/Rooms/helpers';
import { NavigationBox, NavigationButton, StyledChip } from './style';

export type BookingOptionsProps = {
  icon?: (props: SvgIconProps) => JSX.Element;
  title?: string;
  category: string;
  options: {
    label: string;
    value: string | number;
  }[];
  setIsDisabledChoice?: React.Dispatch<React.SetStateAction<boolean>>;
};

const BookingOptions = ({
  icon,
  title,
  category,
  options,
  setIsDisabledChoice,
}: BookingOptionsProps) => {
  const theme = useTheme();
  const { setValue, watch } = useFormContext();
  const selected = watch(category);

  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const currentSlide = options.findIndex((option) => option.value === selected);

  useEffect(() => {
    if (!swiperRef) return;
    if (currentSlide === -1) return;
    if (category === 'day') swiperRef.slideTo(currentSlide - 4, 5);
    if (category === 'time') swiperRef.slideTo(currentSlide - 1, 5);
  }, [swiperRef, currentSlide, category]);

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  return (
    <Box>
      {title && icon && (
        <Box
          display="flex"
          alignItems="center"
          marginBottom="32px"
          marginTop="41px"
        >
          <Icon
            component={icon}
            sx={{
              color: theme.palette.secondary.main,
              fill: 'none',
              width: '24px',
              height: '24px',
              marginRight: '8px',
            }}
          />
          <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
            {title}
          </Typography>
        </Box>
      )}

      <Box display="flex" position="relative" marginBottom="16px">
        {!isBeginning && (
          <NavigationBox
            sx={{
              background:
                'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 76.92%)',
            }}
          >
            <NavigationButton onClick={handleLeftClick}>
              <NavigationIcon
                sx={{
                  padding: '7px',
                }}
              />
            </NavigationButton>
          </NavigationBox>
        )}
        <Swiper
          onSwiper={setSwiperRef}
          mousewheel
          spaceBetween={8}
          shortSwipes={false}
          threshold={10}
          slidesPerView="auto"
          onReachBeginning={() => setIsBeginning(true)}
          onReachEnd={() => setIsEnd(true)}
          onFromEdge={() => {
            setIsBeginning(false);
            setIsEnd(false);
          }}
          initialSlide={currentSlide - 1}
          style={{ width: '100%' }}
        >
          {options.map((option) => {
            const isDisabled = isOptionDisabled(
              category,
              option.value.toString(),
            );
            if (
              (category === 'day' || category === 'time') &&
              setIsDisabledChoice
            ) {
              const isCurrentOptionDisabled = isOptionDisabled(
                category,
                options[currentSlide]?.value.toString(),
              );
              setIsDisabledChoice(isCurrentOptionDisabled);
            }
            return (
              <SwiperSlide key={option.value} style={{ width: 'auto' }}>
                <StyledChip
                  label={option.label}
                  variant={selected === option.value ? 'filled' : 'outlined'}
                  color="secondary"
                  sx={{
                    color: theme.palette.blue[900],
                    fontWeight:
                      selected === option.value
                        ? theme.typography.fontWeightBold
                        : theme.typography.fontWeightRegular,
                    opacity: isDisabled ? 0.3 : 1,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },

                    '& .MuiChip-label': {
                      textOverflow: 'unset',
                    },
                    width: 'fit-content',
                  }}
                  onClick={() =>
                    isDisabled
                      ? {}
                      : setValue(category, option.value, {
                          shouldValidate: true,
                        })
                  }
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {!isEnd && (
          <NavigationBox
            sx={{
              right: '0px',
              background:
                'linear-gradient(270deg, #FFFFFF 22.12%, rgba(255, 255, 255, 0) 100%)',
              textAlign: 'right',
            }}
          >
            <NavigationButton onClick={handleRightClick}>
              <NavigationIcon
                sx={{
                  padding: '7px',
                  transform: 'rotate(180deg)',
                }}
              />
            </NavigationButton>
          </NavigationBox>
        )}
      </Box>
    </Box>
  );
};

BookingOptions.defaultProps = {
  title: '',
  icon: null,
  setIsDisabledChoice: null,
};

export default BookingOptions;
