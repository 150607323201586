import { ReactNode } from 'react';
import { Box, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { breakpoint } from 'styles/breakpoints';

export type BannerProps = {
  radius: string;
  color: string;
  children: ReactNode;
  label: string;
  type?: string;
  noTopMargin?: boolean;
};

const Banner = ({
  radius,
  color,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  label,
  type,
  noTopMargin,
}: BannerProps) => {
  const theme = useTheme();
  const isSmallerResolution = useMediaQuery(breakpoint.lg);

  return (
    <Fade in unmountOnExit>
      <Box position="relative" marginTop={noTopMargin ? '0' : '32px'}>
        <Card
          sx={{
            overflow: 'hidden',
            borderRadius: radius,
            border: `1px solid ${color}`,
            padding: '0 32px',
            background:
              type === 'office'
                ? "white top left url('/plant.svg') no-repeat"
                : 'white',
            backgroundPosition:
              isSmallerResolution && type === 'office' ? '-4px center' : '0 0',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              py: '16px',
              '&:last-child': {
                paddingBottom: '16px',
              },
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            {children}
          </CardContent>
        </Card>
      </Box>
    </Fade>
  );
};

Banner.defaultProps = {
  type: '',
  noTopMargin: false,
};

export default Banner;
