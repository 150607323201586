import { ReactNode } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ArrowButton from 'assets/icons/ArrowButtonSecond';
import { Title } from 'components/Title';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import LocationDropdown from 'components/Common/LocationDropdown';

const HeaderSection = ({
  startDate,
  extendedGrid,
  loc,
  changeLoc,
  element,
  isPartTimeBooking,
}: {
  startDate: string;
  extendedGrid: boolean;
  loc: number | undefined;
  changeLoc: (value: number) => void;
  element: ReactNode;
  isPartTimeBooking: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentSpaceId, spaces } = useAuth();
  const from = moment(startDate);

  return (
    <>
      {extendedGrid ? (
        <Box
          sx={{
            '& svg': { fontSize: '3rem' },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
          display="flex"
          alignItems="center"
          mb="16px"
          gap="8px"
        >
          <ArrowButton
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                (isPartTimeBooking
                  ? routes.main.officeDayBookingsGallery
                  : routes.main.meetingsRoomsGallery)({
                  spaceId: currentSpaceId === 0 ? loc : currentSpaceId,
                }),
              )
            }
          />
          <Title variant="h3">Upcoming & available</Title>
        </Box>
      ) : (
        element
      )}
      <LocationDropdown
        items={spaces.filter((space) => space.id)}
        currentId={loc as number}
        onSelect={changeLoc}
      />
      {extendedGrid && (
        <Typography fontWeight="700" mt="24px">
          {from.format('dddd D MMMM YYYY')}
        </Typography>
      )}
    </>
  );
};

export default HeaderSection;
