import { useEffect, useState, useMemo } from 'react';
import { Box, Button, Drawer, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { filter } from 'lodash-es';
import { useAuth } from 'context/AuthContext';

import { NAVIGATION_LIST } from 'constants/navigation';
import ArrowIcon from 'assets/icons/ArrowIcon';
import routes from 'utils/routes';
import { KEYS } from 'utils/cache';
import NavigationBar, { NAVBAR_HEIGHT } from 'components/Layout/NavigationBar';
import NavigationList, { LinkItem } from './NavigationList';
import { ToggleButton } from './style';

const NavigationDrawer = () => {
  const theme = useTheme();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    !sessionStorage.getItem(KEYS.IS_DRAWER_EXPANDED) ||
      sessionStorage.getItem(KEYS.IS_DRAWER_EXPANDED) === 'true',
  );
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem(KEYS.IS_DRAWER_EXPANDED, isExpanded.toString());
  }, [isExpanded]);

  useEffect(() => {
    if (isSidebarOpen && !isExpanded) {
      setIsExpanded(true);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  }, [location]);

  const firstSection: LinkItem[] = useMemo(() => {
    const selector = isAuthenticated
      ? { authenticated: true }
      : { anonymous: true };
    return filter<LinkItem>(NAVIGATION_LIST.firstSection, selector);
  }, [isAuthenticated]);

  const secondSection: LinkItem[] = useMemo(() => {
    const selector = isAuthenticated
      ? { authenticated: true }
      : { anonymous: true };
    return filter<LinkItem>(NAVIGATION_LIST.secondSection, selector) || [];
  }, [isAuthenticated]);

  return (
    <>
      <NavigationBar
        toggleDrawer={() => setIsSidebarOpen((isOpen) => !isOpen)}
      />
      <Drawer
        variant="permanent"
        sx={{
          width: isExpanded ? '260px' : '112px',
          zIndex: theme.zIndex.appBar,
          [theme.breakpoints.down('md')]: {
            width: isSidebarOpen ? '100%' : '0px',
            position: 'fixed',
            overflow: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: '20px',
            height: '730px',
            zIndex: 1,
            [theme.breakpoints.down('md')]: {
              height: 'auto',
              top: NAVBAR_HEIGHT,
            },
          }}
        >
          <Box
            sx={{
              py: 2,
              px: 4,
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <Button
              onClick={() => navigate(routes.main.dashboard)}
              sx={{
                cursor: 'pointer',
                padding: 0,
              }}
            >
              <img
                src={isExpanded ? '/logo.svg' : '/small-logo.svg'}
                alt="logo"
              />
            </Button>
          </Box>

          <NavigationList items={firstSection} isExpanded={isExpanded} />

          <ToggleButton
            isExpanded={isExpanded}
            onClick={() => setIsExpanded((prevState) => !prevState)}
            sx={{
              width: '48px',
              height: '48px',
              marginLeft: isExpanded ? '236px' : '87px',
              transition: 'all 300ms',

              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <ArrowIcon
              sx={{
                color: 'blue.900',
                padding: '5px',
                transform: 'rotate(180deg)',
              }}
            />
          </ToggleButton>

          <NavigationList items={secondSection} isExpanded={isExpanded} />
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
