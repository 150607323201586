/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Typography, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';

import FindMore from 'pages/MeetingRooms/FindMore';
import CalendarNavigation from 'components/Bookings/Calendar/CalendarNavigation';
import Section from 'components/Section';
import ConfirmationBanner from 'components/Bookings/ConfimationBanner';

import ScrollTopButton from 'components/Common/ScrollTopButton';
import CalendarRow from 'components/Rooms/CalendarRow';
import { State } from 'components/Rooms/BookingSlotsSlider';
import { DEFAULT_STATE } from 'constants/rooms';
import { useAppContext } from 'context/AppContext';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import EmptySection from 'components/Common/EmptySection';
import SadFace from 'assets/icons/SadFace';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';
import { NAVBAR_HEIGHT } from 'components/Layout/NavigationBar';
import HeaderSection from './ResultsViewHeader';

interface CalendarProps {
  changeLoc: (value: number) => void;
  loc: number | undefined;
  extendedGrid: boolean;
  setExtendedGrid: React.Dispatch<React.SetStateAction<boolean>>;
  isPartTimeBooking: boolean;
}
interface Params {
  spaceId: number | undefined;
  capacity: number;
  from: string;
  to: string;
  showAll: boolean;
  isPartTimeBooking: boolean;
}

const Calendar = ({
  changeLoc,
  loc,
  extendedGrid,
  setExtendedGrid,
  isPartTimeBooking,
}: CalendarProps) => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  const [searchParams] = useSearchParams();

  const paramsSpaceId = searchParams.get('spaceId') as string;
  const paramFrom = searchParams.get('from') as string;
  const paramTo = searchParams.get('to') as string;
  const paramCapacity = searchParams.get('capacity') as string;

  const [swiperRef, setSwiperRef] = useState<SwiperCore>();

  const [selectedDate, setSelectedDate] = useState(
    paramFrom
      ? moment(paramFrom)
      : moment().set({
          minutes:
            moment().get('minutes') - (moment().get('minutes') % 15) + 15,
          seconds: 0,
          milliseconds: 0,
        }),
  );
  const [selected, setSelected] = useState<State>(DEFAULT_STATE);
  const [timeIndex, setTimeIndex] = useState(0);

  const defaultStartDate = isPartTimeBooking
    ? moment(selectedDate).startOf('month').set({ hour: 8, minutes: 30 })
    : selectedDate;
  const defaultEndDate = isPartTimeBooking
    ? moment(selectedDate)
        .startOf('month')
        .add(35, 'days')
        .set({ hour: 17, minutes: 30 })
    : selectedDate.clone().add(1, 'hours');

  const defaultParams: Params = {
    spaceId: +paramsSpaceId,
    capacity: Number.isNaN(parseInt(paramCapacity, 10))
      ? 1
      : parseInt(paramCapacity, 10),
    from: (paramFrom ? moment(paramFrom) : defaultStartDate).toISOString(),
    to: (paramTo ? moment(paramTo) : defaultEndDate).toISOString(),
    showAll: !paramCapacity,
    isPartTimeBooking,
  };

  const getRooms = async (params: Params) => {
    const {
      data: { data },
    } = await API.get(APIRoutes.bookings.filtered(params));
    return data;
  };

  const { data, isLoading, isSuccess } = useQuery<Room[]>(
    [
      'rooms',
      { isPartTimeBooking },
      [
        searchParams.get('spaceId'),
        searchParams.get('capacity'),
        searchParams.get('from'),
        searchParams.get('to'),
      ],
      selectedDate,
    ],
    () => getRooms(defaultParams),
  );

  useEffect(() => {
    if (!isEmpty(data) && !isPartTimeBooking) {
      const currentTime = defaultParams.from
        ? moment(defaultParams.from).format('HH:mm')
        : moment()
            .set({
              minutes:
                moment().get('minutes') - (moment().get('minutes') % 15) + 15,
            })
            .format('HH:mm');
      const index = data?.[0].availability.findIndex(
        (item) => moment(item.date).format('HH:mm') === currentTime,
      );
      setTimeIndex(index || 0);
    }
  }, [data, isPartTimeBooking]);

  useEffect(() => {
    const state = extendedGrid
      ? {
          ...DEFAULT_STATE,
          from: defaultParams.from,
          to: defaultParams.to,
        }
      : DEFAULT_STATE;
    setSelected(state);
  }, [selectedDate, loc, extendedGrid, isPartTimeBooking]);

  useEffect(() => {
    if (isSuccess) {
      setExtendedGrid(searchParams.get('from') !== null);

      if (
        searchParams.get('from') !== null &&
        loc === parseInt(searchParams.get('spaceId') as string, 10)
      )
        changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
    }
  }, [data]);

  useEffect(() => {
    if (extendedGrid)
      changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
  }, [isSuccess]);

  useEffect(() => {
    if (swiperRef && !swiperRef.destroyed) {
      swiperRef.slideTo(timeIndex);
    }
  }, [swiperRef, timeIndex]);

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <Section
          height="calc(100% - 250px)"
          sx={{
            padding: '0px 40px 64px 40px',
            background:
              'linear-gradient(rgb(241, 241, 241) 0%, rgba(241, 241, 241, 0) 100%)',
            [theme.breakpoints.down('md')]: {
              padding: '0px 24px 64px 24px',
            },
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 998,
              backgroundColor: 'rgb(241, 241, 241)',
              paddingTop: '32px',
              paddingInline: '40px',
              margin: '0 -40px',

              [theme.breakpoints.down('md')]: {
                top: NAVBAR_HEIGHT,
                paddingInline: '24px',
                margin: '0 -24px',
              },
            }}
          >
            <HeaderSection
              startDate={defaultParams.from}
              extendedGrid={extendedGrid}
              loc={loc}
              changeLoc={changeLoc}
              isPartTimeBooking={isPartTimeBooking}
              element={
                <CalendarNavigation
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  isPartTimeBooking={isPartTimeBooking}
                />
              }
            />
            <Box
              sx={{
                width: '100%',
                paddingTop: '66px',

                [theme.breakpoints.down('md')]: {
                  paddingTop: '24px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 12px',
                }}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '88px',
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}
                />

                <Box
                  sx={{
                    height: '10px',
                    maxWidth: '244px',
                    minWidth: '224px',
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}
                />
                <Box
                  sx={{
                    height: '10px',
                    width: '56px',

                    ...(extendedGrid && {
                      [theme.breakpoints.down('md')]: {
                        width: 0,
                      },
                    }),
                  }}
                />
                <Box flex={1} sx={{ minWidth: 0 }}>
                  <Swiper
                    onSwiper={setSwiperRef}
                    mousewheel
                    spaceBetween={8}
                    slidesPerView="auto"
                    slidesPerGroupAuto
                    normalizeSlideIndex={false}
                    shortSwipes={false}
                    threshold={10}
                    onActiveIndexChange={(e) => {
                      setTimeIndex(e.activeIndex);
                    }}
                    style={{
                      paddingInline: '25px',
                      paddingBottom: '12px',
                    }}
                  >
                    {data[0]?.availability?.map((x) => {
                      const date = moment(x?.date);
                      const time = date.format(
                        isPartTimeBooking ? 'DD.MM' : 'HH:mm',
                      );
                      const showSelectedTime = date.isBetween(
                        selected.from,
                        selected.to,
                        isPartTimeBooking ? 'days' : null,
                        '[]',
                      );

                      return (
                        <SwiperSlide
                          key={x.date}
                          style={{
                            width: '36px',
                            height: isPartTimeBooking ? '24px' : '16px',
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontSize="14px"
                            fontWeight={time.includes(':00') ? 700 : 500}
                            color={showSelectedTime ? '#4537ce' : ''}
                            sx={{
                              lineHeight: '16px',
                              position: 'relative',
                              left: isPartTimeBooking ? '0px' : '-22px',
                              textAlign: 'center',
                            }}
                          >
                            {time}
                            {isPartTimeBooking && (
                              <Typography
                                component="span"
                                sx={{
                                  display: 'block',
                                  fontSize: '12px',
                                }}
                              >
                                {date.format('ddd')}
                              </Typography>
                            )}
                          </Typography>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Box>

                <Box
                  sx={{
                    height: '10px',
                    width: extendedGrid ? '123px' : '56px',

                    ...(extendedGrid && {
                      [theme.breakpoints.down('md')]: {
                        width: 0,
                      },
                    }),
                  }}
                />
              </Box>
            </Box>
          </Box>
          {isEmpty(data) ? (
            <EmptySection
              missing={`there were no ${
                extendedGrid ? 'results' : 'rooms'
              } found`}
              label={extendedGrid ? 'Edit search' : 'Book a room'}
              helperText={
                extendedGrid ? 'Try adjusting your search' : 'Book a room today'
              }
              icon={<SadFace />}
              isEdit={extendedGrid}
              onClick={() => {
                openModal(
                  <MeetingRoomModal
                    initialValues={{
                      location: loc?.toString(),
                    }}
                    isPartTimeBooking={isPartTimeBooking}
                  />,
                );
              }}
            />
          ) : (
            <>
              <Box display="flex" flexDirection="column" gap="16px">
                {data.map((room, index) => (
                  <CalendarRow
                    key={room.id}
                    index={index}
                    room={room}
                    selected={selected}
                    setSelected={setSelected}
                    isResultsView={extendedGrid}
                    timeIndex={timeIndex}
                    setTimeIndex={setTimeIndex}
                    isPartTimeBooking={isPartTimeBooking}
                  />
                ))}
              </Box>
              {!extendedGrid && (
                <FindMore
                  onClick={() => {
                    openModal(
                      <MeetingRoomModal
                        isPartTimeBooking={isPartTimeBooking}
                      />,
                    );
                  }}
                />
              )}
            </>
          )}

          <Box textAlign="center" mt="27px">
            <ScrollTopButton />
          </Box>
        </Section>
        <ConfirmationBanner
          selected={selected}
          setSelected={setSelected}
          isPartTimeBooking={isPartTimeBooking}
        />
      </>
    );
  }
  return null;
};

export default Calendar;
