import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useState } from 'react';

export type HelpAccordionProps = {
  summary: string;
  details: string;
  fullDetails?: string | null;
};

const HelpAccordion = ({
  summary,
  details,
  fullDetails,
}: HelpAccordionProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
      square
      sx={{
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px 32px',
        borderRadius: '32px',
        color: '#1D1954',
        transition: `all ${theme.transitions.duration.standard}ms`,
        '&.Mui-expanded': {
          borderRadius: '32px',
          margin: '0 0',
        },
        '&:before': {
          display: 'none',
        },

        [theme.breakpoints.down('md')]: {
          padding: '10px 16px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            width="40px"
            height="40px"
            border={`1px solid ${theme.palette.secondary.main}`}
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            borderRadius="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              component={isExpanded ? RemoveRoundedIcon : AddRoundedIcon}
              sx={{
                width: '24px',
                height: '24px',
                color: theme.palette.blue[900],
              }}
            />
          </Box>
        }
      >
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {fullDetails && fullDetails.length > 0 && (
          <Typography
            lineHeight="18px"
            sx={{
              cursor: 'default',
              'p, span, a': {
                lineHeight: 'inherit !important',
                fontFamily: 'inherit !important',
                color: 'inherit !important',
                fontSize: 'inherit !important',
              },
            }}
            dangerouslySetInnerHTML={{ __html: fullDetails }}
          />
        )}
        {!fullDetails && (
          <Typography
            lineHeight="18px"
            sx={{
              cursor: 'default',
            }}
          >
            {details}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

HelpAccordion.defaultProps = {
  fullDetails: null,
};

export default HelpAccordion;
