import { Box, Divider, Typography, useTheme } from '@mui/material';
import AccountIcon from 'assets/icons/AccountIcon';

type RoomDetailsProps = {
  name: string;
  district: string;
  capacity: number;
};
const RoomDetails = ({ name, district, capacity }: RoomDetailsProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap="8px"
      minWidth="224px"
      maxWidth="244px"
      sx={{
        [theme.breakpoints.down('md')]: {
          minWidth: 'auto',
          flex: 1,
          maxWidth: '100%',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap="4px"
        ml={2}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'fontWeightBold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            maxWidth: '151px',
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: 'smallFontSize',
            opacity: 0.65,
            lineHeight: '16px',
          }}
          color="text.content"
        >
          {district}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="flex-start">
        {capacity && (
          <Box
            display="flex"
            flexDirection="row"
            height="fit-content"
            alignItems="center"
          >
            <AccountIcon
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
            <Typography
              fontWeight="fontWeightBold"
              lineHeight="18px"
              textAlign="center"
            >
              {capacity}
            </Typography>
          </Box>
        )}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            marginLeft: '6px',

            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default RoomDetails;
