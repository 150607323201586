import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import MeetingRoomsForm from 'components/Rooms/SearchForm';
import OfficeDayBookingsForm from 'components/OfficeDayBookings/SearchForm';

const MeetingRoomModal = ({
  initialValues,
  isBooked = false,
  setLocalization,
  isPartTimeBooking = false,
}: {
  initialValues?: Query;
  isBooked?: boolean;
  setLocalization?: (value: number) => void | undefined;
  isPartTimeBooking?: boolean;
}) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();
  const SearchForm = isPartTimeBooking
    ? OfficeDayBookingsForm
    : MeetingRoomsForm;

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        height: '600px',
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#888',
        },

        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },

        [theme.breakpoints.down('md')]: {
          width: '100vw',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'none',
        },
      }}
    >
      <Box position="sticky" top="0px" zIndex="101" bgcolor="background.card">
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              sx={{
                position: 'absolute',
                top: '16px',
                right: '14px',
                width: '20px',
                height: '20px',
                background: theme.palette.secondary.main,
                '&:hover': {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <CloseIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  color: theme.palette.blue[800],
                }}
              />
            </IconButton>
          }
          title={
            // eslint-disable-next-line no-nested-ternary
            isBooked
              ? 'Edit your meeting room'
              : isPartTimeBooking
              ? 'Search for an office day booking'
              : 'Search for a meeting room'
          }
          titleTypographyProps={{
            variant: 'h2',
          }}
          sx={{
            padding: '32px',
          }}
        />
        {isBooked && (
          <Typography sx={{ padding: '0 32px 32px', marginTop: '-27px' }}>
            {initialValues?.location}
          </Typography>
        )}
        <Divider />
      </Box>
      <CardContent
        sx={{
          padding: '0px 32px 24px 32px',
        }}
      >
        <SearchForm
          initialValues={initialValues}
          isBooked={isBooked}
          setLocalization={setLocalization}
        />
      </CardContent>
    </Card>
  );
};

MeetingRoomModal.defaultProps = {
  initialValues: {},
  isBooked: false,
  setLocalization: null,
  isPartTimeBooking: false,
};

export default MeetingRoomModal;
