import React, { useState, useEffect, useMemo } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import CalendarView from 'pages/MeetingRooms/CalendarView';
import GalleryView from 'pages/MeetingRooms/GalleryView';
import HeaderSection from 'pages/MeetingRooms/HeaderSection';
import Switch from 'components/Common/Switch';
import routes from 'utils/routes';

type MeetingRoomsTypes = 'calendar' | 'gallery';

interface MeetingRoomsProps {
  type: MeetingRoomsTypes;
  isPartTimeBooking?: boolean;
}

const MeetingRooms = ({
  type,
  isPartTimeBooking = false,
}: MeetingRoomsProps) => {
  const theme = useTheme();
  const { currentSpaceId, user, isValidSpaceId } = useAuth();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const roomParams = searchParams.get('capacity');

  const [extendedGrid, setExtendedGrid] = useState(!!roomParams);
  const [params, setParams] = useState({});
  const [localization, setLocalization] = useState<number | undefined>(
    parseInt(searchParams.get('spaceId') as string, 10) ||
      currentSpaceId ||
      user?.spaceId,
  );

  const handleChangeLocalization = (loc: number | undefined) => {
    setLocalization(loc as number | undefined);
  };

  useEffect(() => {
    const paramSpaceId = searchParams.get('spaceId') as string;

    if (roomParams === 'null' || roomParams === null) {
      if (paramSpaceId === currentSpaceId.toString()) {
        handleChangeLocalization(currentSpaceId);
      } else {
        handleChangeLocalization(
          paramSpaceId && isValidSpaceId(paramSpaceId)
            ? +paramSpaceId
            : currentSpaceId,
        );
      }
    } else {
      handleChangeLocalization(+paramSpaceId);
    }
  }, [currentSpaceId]);

  useEffect(() => {
    if (!roomParams) {
      setSearchParams(`?spaceId=${localization}`, { replace: true });
    } else {
      const urlParam = (
        capacity: string | null,
        from: string | null,
        to: string | null,
        loc: any,
      ) => `?capacity=${capacity}&from=${from}&spaceId=${loc}&to=${to}`;

      setSearchParams(
        urlParam(
          searchParams.get('capacity'),
          searchParams.get('from'),
          searchParams.get('to'),
          localization || searchParams.get('spaceId'),
        ),
        { replace: true },
      );
    }
  }, [localization]);

  useEffect(() => {
    if (parseInt(searchParams.get('spaceId') as string, 10) === 0)
      setLocalization(user?.spaceId);
  }, [searchParams.get('spaceId')]);

  useEffect(() => {
    if (!searchParams.get('capacity') === null)
      setParams({ space: searchParams.get('spaceId') });
    if (searchParams.get('capacity') !== null)
      setParams({
        spaceId: searchParams.get('spaceId'),
        capacity: searchParams.get('capacity'),
        from: searchParams.get('from'),
        to: searchParams.get('to'),
      });
  }, [location]);

  const getParams = useMemo(() => {
    if (searchParams.get('capacity') !== null) return params;

    return {
      spaceId: Number.isNaN(localization) ? user?.spaceId : localization,
    };
  }, [params, location]);

  return (
    <>
      <HeaderSection
        isSearch={!!roomParams}
        setLocalization={handleChangeLocalization}
        isPartTimeBooking={isPartTimeBooking}
      />

      <Box position="relative" height="100%">
        <Box
          position={type === 'gallery' ? 'relative' : 'sticky'}
          sx={{
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: 40,
              top: 32,

              [theme.breakpoints.down('md')]: {
                position: 'static',
                width: 'fit-content',
                paddingInline: '24px',
                paddingBottom: '16px',
              },
            }}
          >
            <Link
              to={
                type === 'gallery'
                  ? (isPartTimeBooking
                      ? routes.main.officeDayBookingsCalendar
                      : routes.main.meetingsRoomsCalendar)(getParams)
                  : (isPartTimeBooking
                      ? routes.main.officeDayBookingsGallery
                      : routes.main.meetingsRoomsGallery)(getParams)
              }
              style={{ textDecoration: 'none' }}
            >
              <Switch items={['gallery', 'calendar']} activeItem={type} />
            </Link>
          </Box>
        </Box>

        {type === 'calendar' && (
          <CalendarView
            changeLoc={handleChangeLocalization}
            loc={localization}
            extendedGrid={extendedGrid}
            setExtendedGrid={setExtendedGrid}
            isPartTimeBooking={isPartTimeBooking}
          />
        )}
        {type === 'gallery' && (
          <GalleryView
            changeLoc={handleChangeLocalization}
            loc={localization}
            extendedGrid={extendedGrid}
            setExtendedGrid={setExtendedGrid}
            isPartTimeBooking={isPartTimeBooking}
          />
        )}
      </Box>
    </>
  );
};

MeetingRooms.defaultProps = {
  isPartTimeBooking: false,
};

export default MeetingRooms;
